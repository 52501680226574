import React from 'react';
import 'react-barcode-scanner/polyfill';
import { Button, Modal, ModalBody, TabContent, TabPane } from 'reactstrap';

type ScanBarCodeModalProps = {
  show: boolean;
  onCloseClick: () => void;
};

const ScanBarCodeModal = ({ show, onCloseClick }: ScanBarCodeModalProps) => {
  return (
    <Modal isOpen={show} centered={true} size='sm'>
      <div className='modal-content '>
        <ModalBody className='tw-p-2 tw-flex tw-flex-col tw-items-center tw-gap-4'>
          <TabContent>
            <TabPane>
              <div
                id='qrScanElement'
                className='tw-relative tw-w-[15rem] tw-flex tw-flex-col tw-text-center'
              >
                There is no period to register!
              </div>
            </TabPane>
          </TabContent>
          <Button type='button' color='primary' onClick={onCloseClick}>
            Close
          </Button>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ScanBarCodeModal;
