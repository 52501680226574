import { QrReader } from 'react-qr-reader';
import { Button, Input, Modal, ModalBody, TabContent, TabPane } from 'reactstrap';

import { EventPeriod } from 'types';

type ScanModalProps = {
  eventPeriods: EventPeriod[];
  show: boolean;
  onCloseClick: () => void;
  scanningPhase: string;
  setScanningPhase: (newData: 'Scanning' | 'Processing' | 'Done') => void;
  getParticipantById: (newdata: string) => Promise<void>;
  setScannedPeriodId: (newdata: string) => void;
};

const ScanModal = ({
  eventPeriods,
  show,
  onCloseClick,
  scanningPhase,
  setScanningPhase,
  getParticipantById,
  setScannedPeriodId,
}: ScanModalProps) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size='sm'>
      <div className='modal-content '>
        <ModalBody className='tw-p-2 tw-flex tw-flex-col tw-items-center tw-gap-4'>
          <TabContent>
            <TabPane>
              <div className='tw-flex tw-flex-col tw-items-center tw-gap-4'>
                <div className='tw-font-bold tw-text-[1.5rem]'>Attendance registration</div>
                <Input
                  id='type'
                  name='type'
                  type='select'
                  className='tw-w-[8rem] tw-h-[2.5rem] tw-flex tw-items-center tw-relative'
                  placeholder='Enter period type...'
                  onChange={(e) => {
                    const value = e.target.value;
                    console.log('IN SCAN MODAL', value);
                    setScannedPeriodId(value);
                  }}
                >
                  {eventPeriods.map((period) => (
                    <option key={period._id} value={period._id}>
                      {period.title}
                    </option>
                  ))}
                </Input>
                <div id='qrScanElement' className='tw-relative tw-w-[15rem] tw-flex tw-flex-col'>
                  <QrReader
                    constraints={{ aspectRatio: 1, width: 160 }}
                    onResult={(result, error) => {
                      setScanningPhase('Processing');
                      if (result) {
                        getParticipantById(result.getText());
                      }

                      if (error) {
                        console.info(error);
                      }
                    }}
                    className='tw-relative tw-w-[15rem] tw-aspect-square tw-border-8 tw-border-[#3D4863] tw-border-solid tw-rounded-lg'
                  />
                </div>
                <div
                  className={`${
                    scanningPhase === 'Done'
                      ? 'tw-text-[#0F9D58]'
                      : scanningPhase === 'Done'
                      ? 'tw-text-[#F1B44C]'
                      : ''
                  } tw-font-bold`}
                >
                  {scanningPhase}
                </div>
              </div>
            </TabPane>
          </TabContent>
          <Button type='button' color='primary' onClick={onCloseClick}>
            Close
          </Button>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ScanModal;
