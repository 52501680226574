import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container } from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Pagination from 'Components/Common/Pagination';
import { defaultEvent1 } from 'data/defaultEvent';
import useTitle from 'hooks/useTitle';

import { ReactComponent as BottomWave } from '../../../assets/svg/bottomWave4.svg';

import EventCard from './EventCard';

const limit = 8;

const EventUser = () => {
  useTitle('Event Forum', {
    restoreOnUnmount: true,
  });

  const naviagte = useNavigate();

  const [eventCount, setEventCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setEventCount(16);
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Event' breadcrumbItem='Event forum' />
        </Container>
        <Col>
          <Card className=' tw-overflow-hidden'>
            {/* First section: UPCOMING EVENTS */}
            <div className='tw-relative tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-gap-10 md:tw-justify-between tw-p-4 xl:tw-p-[2.5rem]'>
              <div className='tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-h-[10rem]'>
                <BottomWave />
              </div>
              <div className='tw-relative tw-flex tw-flex-col tw-items-center md:tw-items-start md:tw-mt-[2.5rem]'>
                <div className='tw-relative tw-font-bold tw-text-[2rem]'>UPCOMING EVENTS</div>
                <div className='tw-text-center md:tw-text-start tw-max-w-[30rem] tw-mt-[0.5rem]'>
                  Join us on the journey to explore groundbreaking research, innovative ideas, and
                  the future of knowledge across diverse disciplines.
                </div>
                <Button
                  type='button'
                  color='primary'
                  className='tw-mt-[1.5rem] xl:tw-mt-[2.5rem]'
                  disabled={false}
                  onClick={() => {
                    naviagte('/user/event/all');
                  }}
                >
                  See more
                </Button>
              </div>
              <div className='tw-relative tw-flex tw-flex-row tw-items-center tw-justify-center'>
                <EventCard event={defaultEvent1} type='registered' detailTag={true} banner={true} />
                <div className='tw-hidden sm:tw-flex md:tw-hidden xl:tw-flex tw-ml-[2.5rem]'>
                  <EventCard
                    event={defaultEvent1}
                    type='registered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
              </div>
            </div>
            {/* Second section: UPCOMING EVENTS */}
            <div className='tw-relative tw-flex tw-flex-col tw-w-full tw-gap-10 tw-p-4 xl:tw-p-[2.5rem] tw-bg-primary'>
              <div className='tw-flex tw-flex-col xs:tw-flex-row tw-justify-between xs:tw-items-end tw-text-white'>
                <div className='tw-flex tw-flex-col'>
                  <div className='tw-relative tw-font-bold tw-text-[2rem]'>Featured events</div>
                  <div className='tw-relative'>Other trending events that you may like</div>
                </div>
                <div
                  onClick={() => {
                    naviagte('/user/event/all');
                  }}
                  className='tw-w-[5rem] tw-font-bold tw-self-end tw-cursor-pointer'
                >
                  See more
                </div>
              </div>
              <div className='tw-relative tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 2xl:tw-grid-cols-4 tw-gap-[1.5rem]'>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='unregistered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='registered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='registered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='registered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
              </div>
            </div>
            {/* Third section: EVENTS */}
            <div className='tw-relative tw-flex tw-flex-col tw-w-full tw-gap-10 tw-p-4 xl:tw-p-[2.5rem]'>
              <div className='tw-flex tw-flex-col xs:tw-flex-row tw-justify-between xs:tw-items-end'>
                <div className='tw-flex tw-flex-col tw-relative tw-max-w-[30rem]'>
                  <div className='tw-relative tw-font-bold tw-text-[2rem]'>Events</div>
                  <div className='tw-relative'>
                    Explore the rich history of our past events, where attendees have engaged in
                    insightful discussions, pioneering research presentations, and networking with
                    top experts in their fields.
                  </div>
                </div>
                <div className='tw-flex tw-w-[20rem] xl:tw-w-[35rem] 2xl:tw-w-[40rem] tw-h-[3rem] tw-border-t-4 tw-border-solid tw-border-[#3D4863] tw-self-end tw-justify-end tw-mt-4'>
                  <Pagination
                    count={eventCount}
                    pageSize={limit}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
              <div className='tw-relative tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 2xl:tw-grid-cols-4 tw-gap-[1.5rem]'>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='invitation'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='registered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='registered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center'>
                  <EventCard
                    event={defaultEvent1}
                    type='registered'
                    detailTag={true}
                    banner={true}
                  />
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default EventUser;
