import { useState } from 'react';
import { Input } from 'reactstrap';

import Pagination from 'Components/Common/Pagination';
import { EventRole } from 'types';

import RoleCard from '../RoleCard';

type VerificationRequestParticipantsProps = {
  roles: Array<EventRole>;
  setOpenRole: (role: EventRole) => void;
  searchRoles: () => void;
};

const VerificationRequestRoles = ({
  roles,
  setOpenRole,
  searchRoles,
}: VerificationRequestParticipantsProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className='tw-p-5 tw-p-t-[1.5rem] xl:tw-p-6 tw-rounded-[0.25rem] tw-shadow-[0_8px_16px_0_rgba(61,72,99,0.25)] tw-flex tw-flex-col tw-gap-y-6 tw-justify-between tw-items-center'>
      <span className='tw-text-xl tw-text-[#3D4863] tw-font-bold tw-self-start'>Roles</span>

      <div className='tw-w-full tw-flex tw-flex-col tw-gap-y-8 tw-items-center tw-justify-center tw-px-4'>
        <div className='tw-flex tw-w-full tw-flex-row tw-items-center tw-px-2 tw-relative tw-border-[1px] tw-border-solid tw-border-[#3D4863] tw-rounded-lg'>
          <i className='bx bx-search-alt' />
          <Input
            className='!tw-border-0'
            onKeyUp={searchRoles}
            id='searchbarRole'
            type='text'
            placeholder='Search...'
          />
        </div>

        <div className='tw-w-full tw-justify-items-center tw-relative tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 2xl:tw-grid-cols-4 tw-gap-[1.5rem]'>
          {roles.map((role: EventRole) => (
            <RoleCard setOpenRole={setOpenRole} key={role._id} role={role} />
          ))}
        </div>

        <div className='sm:tw-self-end'>
          <Pagination
            count={roles.length}
            pageSize={10}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default VerificationRequestRoles;
