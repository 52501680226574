import classnames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import { Nav, NavItem, NavLink, TabContent, TabPane, FormGroup } from 'reactstrap';

import { ReactComponent as Bold } from '../../assets/svg/bold.svg';
import { ReactComponent as H1 } from '../../assets/svg/heading-h1.svg';
import { ReactComponent as H2 } from '../../assets/svg/heading-h2.svg';
import { ReactComponent as H3 } from '../../assets/svg/heading-h3.svg';
import { ReactComponent as Italic } from '../../assets/svg/italic.svg';

type MarkdownEditorProps = {
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
};

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({ value, onChange, placeholder }) => {
  const [activeTab, setActiveTab] = useState<'write' | 'preview'>('write');
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const toggleTab = (tab: 'write' | 'preview') => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const coverText = useCallback(
    (left: string, right: string = left) => {
      const textarea = textAreaRef.current;
      if (!textarea) return;

      const { selectionStart, selectionEnd } = textarea;

      if (selectionStart === selectionEnd) {
        const newValue = value.slice(0, selectionStart) + left + right + value.slice(selectionEnd);
        onChange(newValue);

        requestAnimationFrame(() => {
          const cursor = selectionStart + left.length;
          textarea.selectionStart = cursor;
          textarea.selectionEnd = cursor;
          textarea.focus();
        });
      } else {
        const selectedText = value.slice(selectionStart, selectionEnd);
        const removeSpace = selectedText.trim();
        const newValue =
          value.slice(0, selectionStart) + left + removeSpace + right + value.slice(selectionEnd);
        onChange(newValue);

        requestAnimationFrame(() => {
          textarea.selectionStart = selectionStart + left.length;
          textarea.selectionEnd = selectionEnd + left.length;
          textarea.focus();
        });
      }
    },
    [value, onChange]
  );

  const prefixText = useCallback(
    (headingMarker: string) => {
      const textarea = textAreaRef.current;
      if (!textarea) return;

      const { selectionStart, selectionEnd } = textarea;

      if (selectionStart === selectionEnd) {
        const newValue = value.slice(0, selectionStart) + headingMarker + value.slice(selectionEnd);
        onChange(newValue);

        requestAnimationFrame(() => {
          const cursor = selectionStart + headingMarker.length;
          textarea.selectionStart = cursor;
          textarea.selectionEnd = cursor;
          textarea.focus();
        });
      } else {
        const selectedText = value.slice(selectionStart, selectionEnd);
        const removeSpaceText = selectedText.trim();
        const newValue =
          value.slice(0, selectionStart) +
          headingMarker +
          removeSpaceText +
          value.slice(selectionEnd);

        onChange(newValue);
        requestAnimationFrame(() => {
          textarea.selectionStart = selectionStart + headingMarker.length;
          textarea.selectionEnd = selectionEnd + headingMarker.length;
          textarea.focus();
        });
      }
    },
    [value, onChange]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'b') {
        e.preventDefault();
        coverText('**');
      }
      if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'i') {
        e.preventDefault();
        coverText('_');
      }
    },
    [coverText]
  );

  return (
    <>
      <div className='tw-gap-y-0 tw-flex tw-flex-col tw-items-stretch tw-w-full'>
        <div className='tw-flex tw-flex-row tw-justify-between '>
          <Nav tabs className='mb-2'>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'write' })}
                onClick={() => toggleTab('write')}
                style={{ cursor: 'pointer' }}
              >
                Write
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'preview' })}
                onClick={() => toggleTab('preview')}
                style={{ cursor: 'pointer' }}
              >
                Preview
              </NavLink>
            </NavItem>
          </Nav>

          <div className='tw-flex tw-flex-row tw-space-x-2 tw-items-center tw-justify-center tw-mb-2'>
            <button
              type='button'
              onClick={() => coverText('**')}
              className=' tw-w-[20px] xs:tw-w-[28px] tw-h-[20px] xs:tw-h-[28px]'
            >
              <Bold width='100%' height='100%' />
            </button>
            <button
              type='button'
              onClick={() => coverText('_')}
              className=' tw-w-[20px] xs:tw-w-[28px] tw-h-[20px] xs:tw-h-[28px]'
            >
              <Italic width='100%' height='100%' />
            </button>
            <button
              type='button'
              onClick={() => prefixText('# ')}
              className=' tw-w-[20px] xs:tw-w-[28px] tw-h-[20px] xs:tw-h-[28px]'
            >
              <H1 width='100%' height='100%' />
            </button>
            <button
              type='button'
              onClick={() => prefixText('## ')}
              className=' tw-w-[20px] xs:tw-w-[28px] tw-h-[20px] xs:tw-h-[28px]'
            >
              <H2 width='100%' height='100%' />
            </button>
            <button
              type='button'
              onClick={() => prefixText('### ')}
              className=' tw-w-[20px] xs:tw-w-[28px] tw-h-[20px] xs:tw-h-[28px]'
            >
              <H3 width='100%' height='100%' />
            </button>
          </div>
        </div>

        <TabContent activeTab={activeTab} className='tw-w-full'>
          <TabPane tabId='write'>
            <FormGroup className='tw-w-full'>
              <textarea
                id='message'
                name='message'
                ref={textAreaRef}
                rows={6}
                placeholder={placeholder || 'Enter text...'}
                onKeyDown={handleKeyDown}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className='tw-border-[#CFD4D9] tw-w-full tw-bg-gray-100 tw-rounded-md tw-p-3 -tw-mt-[9px] tw-rounded-tl-none tw-rounded-tr-none tw-outline-none tw-border tw-border-gray-300 tw-resize-none no-scrollbar'
              />
            </FormGroup>
          </TabPane>

          <TabPane tabId='preview'>
            <div className='tw-border-[#CFD4D9] tw-w-full tw-bg-gray-100 tw-rounded-md tw-p-3 -tw-mt-[9px] tw-rounded-tl-none tw-rounded-tr-none tw-outline-none tw-border tw-border-gray-300 tw-resize-none no-scrollbar tw-min-h-[170px] tw-mb-[23px]'>
              <Markdown>{value}</Markdown>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default MarkdownEditor;
