import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Input, Modal, ModalBody, Row } from 'reactstrap';
import { useDebounceCallback } from 'usehooks-ts';

import { handleAxiosError } from 'helpers/handleError';
import { isEmail } from 'helpers/validator';
import UserService from 'services/user.service';
import { User } from 'types';

interface ManagerKeyModalProps {
  show: boolean;
  oldUserList: User[];
  onSubmit: (userList: User[]) => void | void;
  onCloseClick: () => void;
}

const AddMemberModal = ({ show, oldUserList, onSubmit, onCloseClick }: ManagerKeyModalProps) => {
  const [userList, setUserList] = useState<User[]>([]);
  // const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debounceValue = useDebounceCallback(setSearchValue, 500);
  const [searchUserList, setSearchUserList] = useState<User[]>([]);

  const searchUser = async () => {
    try {
      const { data } = await UserService.getUserList(20);
      const { payload } = data;
      console.log('here 4');
      setSearchUserList(payload.userList);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  };

  const searchUserByEmail = async (email: string) => {
    try {
      const { data } = await UserService.getUserList(20, email, '');
      const { payload } = data;
      console.log('here 5');
      setSearchUserList(
        payload.userList
        // .filter((user) => {return !oldUserList.find((oldMember) => oldMember === user)})
      );
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  };

  const searchUserByName = async (name: string) => {
    try {
      const { data } = await UserService.getUserList(20, '', encodeURIComponent(name));
      const { payload } = data;
      console.log('here 6');
      setSearchUserList(payload.userList);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  };

  const addMember = (user: User) => {
    if (!userList.find((member) => member._id === user._id)) {
      const newUserList = [...userList];
      newUserList.push(user);
      setUserList(newUserList);
    }
  };

  const removeMember = (user: User) => {
    const newUserList = userList.filter((member) => {
      return member._id !== user._id;
    });
    setUserList(newUserList);
  };

  useEffect(() => {
    if (searchValue) {
      if (isEmail(searchValue)) {
        console.log(searchValue);
        searchUserByEmail(searchValue);
      } else searchUserByName(searchValue);
    } else searchUser();
  }, [searchValue]);

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size='large'>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Add member</h5>
        <button
          type='button'
          onClick={onCloseClick}
          className='btn-close position-absolute end-0 top-0 m-3'
        />
      </div>
      <div className='modal-content '>
        <ModalBody className='px-4 py-4 text-center'>
          <div className='mb-3'>
            <Row>
              <Input
                id='searchUser'
                name='searchUser'
                className='form-control'
                type='text'
                placeholder='Enter user email or name to search...'
                onChange={() => {
                  const input = document.getElementById('searchUser') as HTMLInputElement;
                  debounceValue(input.value);
                }}
              />
            </Row>
          </div>

          <div
            className='hide-scrollbar mb-4'
            style={{ maxHeight: '400px', overflowY: 'scroll', overflowX: 'hidden' }}
          >
            {searchUserList
              .filter((member: User) => {
                return !oldUserList.find((oldMember) => oldMember._id === member._id);
              })
              .map((member: User) => (
                <Row key={member._id} className='mb-4' style={{ alignItems: 'center' }}>
                  <Col>
                    <div className='font-size-16 text-start' style={{ height: 'full' }}>
                      {member.name}
                    </div>
                  </Col>
                  <Col className='text-end'>
                    {!userList.find((user) => user === member) && (
                      <i
                        className='text-success mdi mdi-book-plus font-size-18'
                        onClick={() => addMember(member)}
                      />
                    )}
                    {userList.find((user) => user === member) && (
                      <i
                        className='text-danger mdi mdi-delete font-size-18'
                        onClick={() => removeMember(member)}
                      />
                    )}
                  </Col>
                </Row>
              ))}
          </div>

          <div className='hstack gap-2 justify-content-end mb-0'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={() => {
                setSearchValue('');
                setUserList([]);
                onCloseClick();
              }}
            >
              Cancel
            </button>
            <button
              type='button'
              disabled={userList.length <= 0}
              className='btn btn-primary'
              onClick={() => {
                onSubmit(userList);
                setSearchValue('');
                setUserList([]);
                onCloseClick();
              }}
            >
              Add {userList.length} member
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default AddMemberModal;
