import classnames from 'classnames';
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import { defaultEvent1 } from 'data/defaultEvent';
import { handleAxiosError } from 'helpers/handleError';
// import useAppSelector from 'hooks/useAppSelector';
import EventCoreService from 'services/event-core.service';
import EventRoleService from 'services/event-role.service';
import ParticipantService from 'services/participant.service';
import {
  Event,
  EventPermissions,
  EventRole,
  EventStatus,
  EventVerificationForOrganizers,
  ParticipantEvent,
} from 'types/core';

import CreateRoleModal from './modal/CreateRoleModal';
import EditRoleModal from './modal/EditRoleModal';
import ShowMessagesVerificationModal from './modal/ShowMessagesVerificationModal';
import ThumbnailModal from './modal/ThumbnailModal';
import VerificationRequestModal from './modal/VerificationRequestModal';
import VerificationRequestModalWithMessage from './modal/VerificationRequestModalWithMessage';
enum Tab {
  BASIC_INFO = 'BASIC_INFO',
  TIMELINE = 'TIMELINE',
  PARTICIPANTS = 'PARTICIPANTS',
  ROLES = 'ROLES',
  OBSERVATION = 'OBSERVATION',
  HISTORY = 'HISTORY',
}
const EventBasicInformation = lazy(() => import('./EventBasicInformation'));
const EventRoles = lazy(() => import('./EventRoles'));
const EventTimeline = lazy(() => import('./EventTimeline'));
const EventParticipants = lazy(() => import('./EventParticipants'));
const EventObservations = lazy(() => import('./EventObservations'));
const EventHistory = lazy(() => import('./EventHistory'));
const tabs = [
  { key: Tab.BASIC_INFO, label: 'Information' },
  { key: Tab.TIMELINE, label: 'Timeline' },
  { key: Tab.PARTICIPANTS, label: 'Participants' },
  { key: Tab.ROLES, label: 'Roles' },
  { key: Tab.OBSERVATION, label: 'Observation' },
  { key: Tab.HISTORY, label: 'History' },
];

const EventDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const handleReturn = () => {
    navigate(-1);
  };
  // const { user } = useAppSelector((state) => state.Login);
  const [meParticipant, setMeParticipant] = useState<ParticipantEvent | null>(null);
  const [event, setEvent] = useState<Event>(defaultEvent1);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>(Tab.BASIC_INFO);

  // Modals
  const [showVerificationRequestModal, setShowVerificationRequestModal] = useState<boolean>(false);
  const [showVerificationRequestWithMessageModal, setShowVerificationRequestWithMessageModal] =
    useState<boolean>(false);
  const [showMessagesVerificationModal, setShowMessagesVerificationModal] =
    useState<boolean>(false);
  const [showThumbnailModal, setShowThumbnailModal] = useState(false);
  const [openEditRoleModal, setOpenEditRoleModal] = useState<EventRole | null>(null);
  const [openCreateRoleModal, setOpenCreateRoleModal] = useState(false);
  const [showAttendeesVerificationModal, setShowAttendeesVerificationModal] = useState(false);
  const [showDeleteAttendeesModal, setShowDeleteAttendeesModal] = useState(false);

  const [myPermissions, setMyPermissions] = useState<EventPermissions[]>([]);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);

  // Information
  const [croppedThumbnail, setCroppedThumbnail] = useState<Blob | null>(null);

  // Roles
  const [roles, setRoles] = useState<Array<EventRole>>([]);
  const [filteredRoles, setFilteredRoles] = useState<Array<EventRole>>([]);
  // Verification
  const [eventVerification, setEventVerification] = useState<EventVerificationForOrganizers>();

  const fetchDataFlowOrganizersAndParticipants = useCallback(
    async (doCheckPermissions = true) => {
      try {
        if (!id) return;
        setLoading(true);
        const { data: participatedEvent } = await ParticipantService.getMyEventById(id);
        if (!participatedEvent.payload) {
          setHasPermission(false);
          return;
        }
        setHasPermission(true);
        setMeParticipant(participatedEvent.payload);
        setEvent(participatedEvent.payload.event);
        setRoles(participatedEvent.payload.event.roles);
        setFilteredRoles(participatedEvent.payload.event.roles);
        if (doCheckPermissions) {
          const { data: perm } = await ParticipantService.getMyPermissions(id);
          setMyPermissions(perm.payload);
          if (
            perm.payload.includes(EventPermissions.LEADER) ||
            perm.payload.includes(EventPermissions.VIEW_REVIEW)
          ) {
            const { data: resEventVerification } =
              await EventCoreService.getEventVerificationByIdForUser(id);
            setEventVerification(resEventVerification.payload[0] as EventVerificationForOrganizers);
          }
        }
      } catch (error: unknown) {
        setHasPermission(false);
      } finally {
        setLoading(false);
      }
    },
    [id]
  );
  const searchRoles = () => {
    const input = document.getElementById('searchbarRole') as HTMLInputElement;
    const filteredList = roles.filter((role: EventRole) => {
      return role.title.toLowerCase().includes(input.value.toLowerCase());
    });
    setFilteredRoles(filteredList);
  };

  useEffect(() => {
    fetchDataFlowOrganizersAndParticipants();
  }, [fetchDataFlowOrganizersAndParticipants]);

  const updateThumbnail = async () => {
    try {
      setLoading(true);
      if (!croppedThumbnail) {
        toast.error('There is nothing to update yet!');
        return;
      }

      const thumbnailBlob: Blob = croppedThumbnail;

      const thumbnailFile = new File([thumbnailBlob], `${event.title}Thumbnail.png`, {
        type: 'image/png',
      });

      await EventCoreService.uploadPoster(event._id, thumbnailFile);

      await fetchDataFlowOrganizersAndParticipants(false);
      setCroppedThumbnail(null);
      toast.success(`Event's poster has been updated!`);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const submitVerification = async (comment: string) => {
    try {
      setShowVerificationRequestModal(false);
      setShowVerificationRequestWithMessageModal(false);
      setLoading(true);
      await EventCoreService.submitVerificationUser({ eventId: event._id, comment });
      await fetchDataFlowOrganizersAndParticipants(true);
      toast.success(`Your verification request is submitted!`);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const getParticipantsByRole = async (roleId: string) => {
    try {
      const { data } = await EventRoleService.getParticipantsByRole(id || '', roleId);
      const { payload } = data;
      if (payload) return payload;
      return [];
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  };

  const createRole = async (role: {
    title: string;
    description: string;
    maxRegistration: number;
    permissions: Array<EventPermissions>;
  }) => {
    try {
      setOpenCreateRoleModal(false);
      setLoading(true);

      if (roles.find((oldRole) => oldRole.title === role.title)) {
        toast.error('The title of this role already exists. Please use another title!');
        return;
      }

      // This is a temporary version without the use of get one event API so the id in this case may be undefined
      // Will be updated to set the event object instead once event core service suffices
      const { data } = await EventRoleService.createRole(id || '', role);
      const { payload } = data;
      setRoles(payload.roles);
      setFilteredRoles(payload.roles);
      setLoading(false);
      toast.success(`A new role has been added`);
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const updateRole = async (
    roleId: string,
    role: {
      _id: string;
      title: string;
      description: string;
      maxRegistration: number;
      permissions: Array<EventPermissions>;
    }
  ) => {
    try {
      setOpenEditRoleModal(null);
      setLoading(true);
      const participants = await getParticipantsByRole(roleId);
      if (participants && participants.length > role.maxRegistration) {
        toast.error('New max registration number is lower than current participants of this role');
        return;
      }

      // This is a temporary version without the use of get one event API so the id in this case may be undefined
      // Will be updated to set the event object instead once event core service suffices
      await EventRoleService.updateRole(id || '', role);
      await fetchDataFlowOrganizersAndParticipants(true);
      setLoading(false);
      toast.success(`Role ${role.title} has been updated`);
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const deleteRole = async (role: EventRole) => {
    try {
      setLoading(true);
      // This is a temporary version without the use of get one event API so the id in this case may be undefined
      // Will be updated once event core service suffices
      const { data } = await EventRoleService.deleteRole(id || '', role._id);
      const { payload } = data;
      setRoles(payload.roles);
      const afterFilteredRoles = payload.roles.filter((rolesFilter) => !rolesFilter.deactivatedAt);
      setFilteredRoles(afterFilteredRoles);
      setLoading(false);
      toast.success(`Role ${role.title} has been deactivated`);
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };
  const shouldRenderTab = (
    tabKey: Tab,
    eventStatus: EventStatus,
    permissions: EventPermissions[]
  ): boolean => {
    switch (tabKey) {
      case Tab.ROLES:
        return permissions.includes(EventPermissions.LEADER);

      case Tab.OBSERVATION:
        const observationPermissions = [
          EventPermissions.LEADER,
          EventPermissions.GENERATE_REPORT,
          EventPermissions.MODIFY_SOCIAL_DAY,
        ].some((permission) => permissions.includes(permission));
        return eventStatus === EventStatus.POST_EVENT && observationPermissions;
      default:
        return true;
    }
  };

  if (hasPermission === null) {
    return <div></div>;
  }

  if (hasPermission === false) {
    return (
      <div
        className='account-pages my-5 pt-5 d-flex align-items-center justify-content-center'
        style={{ minHeight: '100vh' }}
      >
        <Container>
          <Row className='justify-content-center'>
            <Col lg='6'>
              <div className='text-center mb-5'>
                <h1 className='display-2 fw-medium'>
                  4<i className='bx bx-buoy bx-spin text-primary display-3' />4
                </h1>
                <h4 className='text-uppercase'>Page not found</h4>
                <div className='mt-5 text-center'>
                  <button
                    type='button'
                    className='btn btn-primary waves-effect waves-light'
                    onClick={handleReturn}
                  >
                    Return
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  return (
    <React.Fragment>
      <VerificationRequestModalWithMessage
        show={showVerificationRequestWithMessageModal}
        onCloseClick={() => setShowVerificationRequestWithMessageModal(false)}
        onSendClick={submitVerification}
      />
      <VerificationRequestModal
        show={showVerificationRequestModal}
        onCloseClick={() => setShowVerificationRequestModal(false)}
        onSendClick={submitVerification}
      />
      <ShowMessagesVerificationModal
        show={showMessagesVerificationModal}
        onCloseClick={() => setShowMessagesVerificationModal(false)}
        eventVerification={eventVerification}
      />
      {(myPermissions?.includes(EventPermissions.LEADER) ||
        myPermissions?.includes(EventPermissions.MODIFY_DATA)) && (
        <ThumbnailModal
          defaultImage={event.poster?.preview}
          showThumbnailModal={showThumbnailModal}
          onCloseClick={() => setShowThumbnailModal(false)}
          setCroppedImageBlob={setCroppedThumbnail}
        />
      )}
      {myPermissions?.includes(EventPermissions.LEADER) && (
        <EditRoleModal
          role={openEditRoleModal}
          onCloseClick={() => setOpenEditRoleModal(null)}
          onUpdateClick={updateRole}
          onDeleteClick={deleteRole}
        />
      )}
      {myPermissions?.includes(EventPermissions.LEADER) && (
        <CreateRoleModal
          open={openCreateRoleModal}
          onCloseClick={() => setOpenCreateRoleModal(false)}
          onCreateClick={createRole}
        />
      )}
      {/* confirm send attendees verification request */}
      <Modal
        isOpen={showAttendeesVerificationModal}
        toggle={() => setShowAttendeesVerificationModal(!showAttendeesVerificationModal)}
        centered
        size='lg'
        contentClassName='tw-rounded-[0.5rem]'
      >
        <ModalBody>
          <div className='tw-flex tw-flex-col tw-gap-y-7 tw-items-start'>
            <span className='tw-font-bold tw-text-[#3D4863] tw-text-2xl'>
              Confirm and send verification request
            </span>

            <p>
              Once your verification has been sent, this event can not be modified or deleted until
              there is a response for it.
              <br></br>
              <br></br>
              Proceed to do this?
            </p>

            <div className='tw-flex tw-flex-row tw-w-full tw-justify-end tw-items-center tw-gap-x-4'>
              <Button
                type='button'
                color='primary'
                size='sm'
                style={{
                  fontSize: '1rem',
                  color: '#0B2878',
                  fontWeight: 'bold',
                  borderRadius: '0.5rem',
                  borderColor: '#0B2878',
                  backgroundColor: '#fff',
                }}
                onClick={() => setShowAttendeesVerificationModal(false)}
              >
                Cancel
              </Button>
              <Button
                type='button'
                color='primary'
                size='sm'
                style={{
                  fontSize: '1rem',
                  color: '#fff',
                  fontWeight: 'bold',
                  borderRadius: '0.5rem',
                }}
              >
                Confirm and send
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* confirm delete participant */}
      <Modal
        isOpen={showDeleteAttendeesModal}
        toggle={() => setShowDeleteAttendeesModal(!showDeleteAttendeesModal)}
        centered
        size='lg'
        contentClassName='tw-rounded-[0.5rem]'
      >
        <ModalBody>
          <div className='tw-flex tw-flex-col tw-gap-y-7 tw-items-start'>
            <span className='tw-font-bold tw-text-[#DA4437] tw-text-2xl'>Delete participant</span>

            <p>
              Once you delete this participant, this action can not be undone.
              <br></br>
              <br></br>
              Proceed to do this?
            </p>

            <div className='tw-flex tw-flex-row tw-w-full tw-justify-end tw-items-center tw-gap-x-4'>
              <Button
                type='button'
                color='danger'
                size='sm'
                style={{
                  fontSize: '1rem',
                  color: '#fff',
                  fontWeight: 'bold',
                  borderRadius: '0.5rem',
                }}
              >
                Delete
              </Button>
              <Button
                type='button'
                color='primary'
                size='sm'
                style={{
                  fontSize: '1rem',
                  color: '#3D4863',
                  fontWeight: 'bold',
                  borderRadius: '0.5rem',
                  borderColor: '#3D4863',
                  backgroundColor: '#fff',
                }}
                onClick={() => setShowDeleteAttendeesModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Event' breadcrumbItem='Create' backTo='/event' />
          <Row>
            <Col xs={12}>
              <Nav tabs role='tablist' className='nav-tabs-custom'>
                {tabs.map((tab) => {
                  const renderTab = shouldRenderTab(tab.key, event.status, myPermissions);

                  if (!renderTab) {
                    return null;
                  }
                  return (
                    <NavItem key={tab.key}>
                      <NavLink
                        className={classnames({
                          active: activeTab === tab.key,
                        })}
                        onClick={() => {
                          setActiveTab(tab.key);
                        }}
                      >
                        {tab.label}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
              <Card
                style={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                <TabContent activeTab={activeTab}>
                  {/* Basic information section */}
                  <TabPane tabId={Tab.BASIC_INFO} id={Tab.BASIC_INFO}>
                    <Suspense
                      fallback={
                        <div>
                          <Loading width={320} height={320} />
                        </div>
                      }
                    >
                      {meParticipant && (
                        <EventBasicInformation
                          myPermissions={myPermissions}
                          eventVerification={eventVerification}
                          event={event}
                          loading={loading}
                          thumbnail={
                            (croppedThumbnail && URL.createObjectURL(croppedThumbnail)) ||
                            event.poster?.preview
                          }
                          thumbnailUpdate={croppedThumbnail !== null}
                          setShowThumbnailModal={setShowThumbnailModal}
                          setShowVerificationRequestWithMessageModal={
                            setShowVerificationRequestWithMessageModal
                          }
                          setShowVerificationModal={setShowVerificationRequestModal}
                          setShowMessagesVerificationModal={setShowMessagesVerificationModal}
                          updateThumbnail={updateThumbnail}
                          meParticipant={meParticipant}
                        />
                      )}
                    </Suspense>
                  </TabPane>
                  {/* Roles section */}
                  {activeTab === Tab.ROLES && (
                    <TabPane tabId={Tab.ROLES} id={Tab.ROLES}>
                      <Suspense
                        fallback={
                          <div>
                            <Loading width={320} height={320} />
                          </div>
                        }
                      >
                        <EventRoles
                          roles={filteredRoles}
                          loading={loading}
                          setOpenEditRoleModal={setOpenEditRoleModal}
                          setOpenCreateModal={() => setOpenCreateRoleModal(true)}
                          searchRoles={searchRoles}
                        />
                      </Suspense>
                    </TabPane>
                  )}
                  {/* Timeline section */}
                  {activeTab === Tab.TIMELINE && (
                    <TabPane tabId={Tab.TIMELINE} id={Tab.TIMELINE}>
                      <Suspense
                        fallback={
                          <div>
                            <Loading width={320} height={320} />
                          </div>
                        }
                      >
                        <EventTimeline
                          eventStartTime={event.startAt}
                          eventEndTime={event.endAt}
                          loading={loading}
                        />
                      </Suspense>
                    </TabPane>
                  )}
                  {/* Attendees section */}
                  {activeTab === Tab.PARTICIPANTS && (
                    <TabPane tabId={Tab.PARTICIPANTS} id={Tab.PARTICIPANTS}>
                      <Suspense
                        fallback={
                          <div>
                            <Loading width={320} height={320} />
                          </div>
                        }
                      >
                        <EventParticipants
                          event={event}
                          myPermissions={myPermissions}
                          roles={roles}
                          loading={loading}
                        />
                      </Suspense>
                    </TabPane>
                  )}
                  {/* Attendees section */}
                  {activeTab === Tab.OBSERVATION && (
                    <TabPane tabId={Tab.OBSERVATION} id={Tab.OBSERVATION}>
                      <Suspense
                        fallback={
                          <div>
                            <Loading width={320} height={320} />
                          </div>
                        }
                      >
                        <EventObservations myPermissions={myPermissions} event={event} />
                      </Suspense>
                    </TabPane>
                  )}
                  {/* History section */}
                  {activeTab === Tab.HISTORY && (
                    <TabPane tabId={Tab.HISTORY} id={Tab.HISTORY}>
                      {event._id !== 'defaultId1' && (
                        <Suspense
                          fallback={
                            <div>
                              <Loading width={320} height={320} />
                            </div>
                          }
                        >
                          <EventHistory eventId={event?._id} />
                        </Suspense>
                      )}
                    </TabPane>
                  )}
                </TabContent>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EventDetail;
