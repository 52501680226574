import { useFormik } from 'formik';
import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import * as Yup from 'yup';

import Breadcrumb from 'Components/Common/Breadcrumb';
import { handleAxiosError } from 'helpers/handleError';
import EventCoreService from 'services/event-core.service';

import { ReactComponent as Upload } from '../../assets/svg/cloud_upload.svg';

import ThumbnailModal from './modal/ThumbnailModal';
// import VerificationRequestModal from './modal/VerificationRequestModal';

const initialValue = {
  title: '',
  description: '',
  location: '',
  startAt: null,
  endAt: null,
};
interface EventMetadata {
  title: string;
  description: string;
  location: string;
  startAt: number;
  endAt: number;
}

const CreateEvent = () => {
  const [showThumbnailModal, setShowThumbnailModal] = useState<boolean>(false);

  const [croppedThumbnail, setCroppedThumbnail] = useState<Blob | null>(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const createEvent = async (metadata: EventMetadata) => {
    setLoading(true);
    if (!metadata) {
      toast.error('There is no metadata to create event yet');
      setLoading(false);
      return;
    }
    try {
      const { data } = await EventCoreService.createEvent(metadata);
      const { payload } = data;
      if (croppedThumbnail) {
        try {
          const thumbnailFile = new File([croppedThumbnail], `${payload.title}Thumbnail.png`, {
            type: 'image/png',
          });

          await EventCoreService.uploadPoster(payload._id, thumbnailFile);
        } catch (errrorUpload: unknown) {
          handleAxiosError(errrorUpload, (message) => toast.error(message));
        }
      }

      toast.success(`Event ${payload.title} has been created!`);
      navigate(`/event/${payload._id}`);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const validation = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object({
      title: Yup.string().required('Please enter event title'),
      description: Yup.string(),
      location: Yup.string().required('Please enter event location'),
      startAt: Yup.date().required('Please enter start time'),
      endAt: Yup.date().required('Please enter end time'),
    }),
    onSubmit: (values) => {
      if (!values.startAt) {
        toast.error('You must enter start time');
        return;
      }
      if (!values.endAt) {
        toast.error('You must enter end time');
        return;
      }
      if (values.startAt >= values.endAt) {
        toast.error('The end time must be later than the start time');
        return;
      }
      const newMetadata = {
        title: values.title,
        description: values.description,
        location: values.location,
        startAt: new Date(values.startAt).getTime(),
        endAt: new Date(values.endAt).getTime(),
      };
      createEvent(newMetadata);
    },
  });

  return (
    <React.Fragment>
      <ThumbnailModal
        showThumbnailModal={showThumbnailModal}
        onCloseClick={() => setShowThumbnailModal(false)}
        setCroppedImageBlob={setCroppedThumbnail}
      />

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Event' breadcrumbItem='Create' backTo='/event' />
          <Row>
            <Col xs={12}>
              <Card>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  autoComplete='off'
                  className='tw-p-5 tw-p-t-[1.5rem] tw-h-full xl:tw-p-6 xl:tw-p-t-[1.75rem] tw-rounded-b-[0.25rem] tw-shadow-[0_8px_16px_0_rgba(61,72,99,0.25)] tw-flex !tw-flex-col tw-items-center sm:tw-items-start sm:!tw-flex-row tw-gap-6 xl:tw-justify-between'
                >
                  {/* Basic info section */}
                  <div className='tw-order-first sm:tw-order-last tw-w-full xl:tw-min-h-[33.25rem] !tw-flex !tw-flex-col xl:!tw-justify-between'>
                    <div className='!tw-flex !tw-flex-col tw-h-full'>
                      <span className='tw-text-[#3D4863] tw-font-bold xl:tw-text-xl tw-mb-2 xl:tw-mb-4 tw-w-full tw-whitespace-nowrap'>
                        Create a new event
                      </span>
                      <FormGroup className='tw-mb-2' row>
                        <Label
                          htmlFor='title'
                          className='col-form-label tw-w-full xl:!tw-w-[7.5rem] tw-font-bold'
                        >
                          Title:
                        </Label>
                        <Col className='tw-w-full'>
                          <Input
                            id='title'
                            className='form-control'
                            name='title'
                            type='text'
                            placeholder='Enter event title...'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ''}
                            invalid={
                              validation.touched.title && validation.errors.title ? true : false
                            }
                          />
                          {validation.touched.title && validation.errors.title ? (
                            <FormFeedback type='invalid'>{validation.errors.title}</FormFeedback>
                          ) : null}
                        </Col>
                      </FormGroup>

                      <FormGroup className='tw-mb-2' row>
                        <Label
                          htmlFor='description'
                          className='col-form-label tw-w-full xl:!tw-w-[7.5rem] tw-font-bold'
                        >
                          Description:
                        </Label>
                        <Col className='tw-w-full'>
                          <Input
                            tag='textarea'
                            id='description'
                            className='form-control'
                            name='description'
                            type='text'
                            rows={4}
                            placeholder='Enter event description...'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ''}
                            invalid={
                              validation.touched.description && validation.errors.description
                                ? true
                                : false
                            }
                          />
                          {validation.touched.description && validation.errors.description ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </FormGroup>

                      <FormGroup className='tw-mb-2' row>
                        <Label
                          htmlFor='location'
                          className='col-form-label tw-w-full xl:!tw-w-[7.5rem] tw-font-bold'
                        >
                          Location:
                        </Label>
                        <Col className='tw-w-full'>
                          <Input
                            id='location'
                            className='form-control'
                            name='location'
                            type='text'
                            placeholder='Enter event location...'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.location || ''}
                            invalid={
                              validation.touched.location && validation.errors.location
                                ? true
                                : false
                            }
                          />
                          {validation.touched.location && validation.errors.location ? (
                            <FormFeedback type='invalid'>{validation.errors.location}</FormFeedback>
                          ) : null}
                        </Col>
                      </FormGroup>

                      <FormGroup className='tw-mb-2' row>
                        <Label
                          htmlFor='time'
                          className='col-form-label tw-w-full xl:!tw-w-[7.5rem] tw-font-bold'
                        >
                          Time:
                        </Label>
                        <Col className='tw-w-full tw-flex tw-flex-col xl:tw-flex-row tw-gap-2 md:tw-gap-4'>
                          <Label htmlFor='startAt' className='col-form-label tw-w-fit'>
                            From:
                          </Label>
                          <div className='tw-flex tw-flex-col'>
                            <Flatpickr
                              name='startAt'
                              className='form-control'
                              value={validation.values.startAt || ''}
                              onChange={([date]) => {
                                validation.setFieldValue('startAt', date);
                              }}
                              options={{
                                mode: 'single',
                                altFormat: 'd/m/Y H:i',
                                dateFormat: 'd/m/Y H:i',
                                enableTime: true,
                              }}
                            />
                            {validation.touched.startAt && validation.errors.startAt ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.startAt}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <Label htmlFor='endAt' className='col-form-label tw-w-fit'>
                            to:
                          </Label>
                          <div className='tw-flex tw-flex-col'>
                            <Flatpickr
                              name='endAt'
                              className='form-control'
                              value={validation.values.endAt || ''}
                              onChange={([date]) => {
                                validation.setFieldValue('endAt', date);
                              }}
                              options={{
                                mode: 'single',
                                altFormat: 'd/m/Y H:i',
                                dateFormat: 'd/m/Y H:i',
                                enableTime: true,
                              }}
                            />
                            {validation.touched.endAt && validation.errors.endAt ? (
                              <FormFeedback type='invalid'>{validation.errors.endAt}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </FormGroup>
                    </div>

                    <div className='tw-hidden sm:tw-flex tw-relative tw-flex-row tw-self-end tw-gap-4'>
                      <Button
                        type='button'
                        color='primary'
                        outline
                        disabled={loading}
                        onClick={() => navigate('/event')}
                      >
                        Cancel
                      </Button>

                      {/* For now there is */}
                      <Button type='submit' color='primary' disabled={loading}>
                        Create
                      </Button>
                    </div>
                  </div>

                  {/* thumbnail section */}
                  <div className='tw-flex tw-flex-col tw-gap-y-6 tw-w-full sm:tw-w-fit'>
                    {/* <div className='tw-flex tw-flex-col tw-gap-y-3'> */}
                    <div className='tw-flex tw-flex-col tw-gap-y-3 xs:tw-flex-row md:tw-h-fit xs:tw-justify-between '>
                      <span className='tw-font-bold tw-text-[#3D4863] xl:tw-text-xl'>Poster</span>
                    </div>

                    <div
                      className='tw-self-center tw-flex tw-flex-col tw-w-[15rem] xs:tw-w-[22.5rem] sm:tw-w-[15rem] lg:tw-w-[18rem] 2xl:tw-w-[22.5rem]  tw-aspect-[3/4] tw-items-center tw-justify-center tw-rounded-xl tw-bg-[#A3ACC2] tw-cursor-pointer tw-relative'
                      onClick={() => {
                        setShowThumbnailModal(true);
                      }}
                    >
                      {croppedThumbnail && (
                        <>
                          <img
                            src={URL.createObjectURL(croppedThumbnail)}
                            className='tw-w-full tw-h-full tw-object-contain'
                            alt='event thumbnail'
                          />
                          <div className='tw-absolute tw-opacity-0 tw-w-full tw-h-full group-hover:tw-opacity-[80%] tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-y-4 md:tw-gap-y-6 tw-transform tw-bg-[#3D4863] tw-z-50 tw-duration-200 tw-ease-in-out'>
                            <Upload width={40} height={40} fill={'#FFFFFF'} />
                            <span className='tw-text-xs xs:tw-text-base sm:tw-text-xs xl:tw-text-base tw-text-center tw-text-white  md:tw-whitespace-nowrap'>
                              Upload a poster for your event (3 : 4)
                            </span>
                          </div>
                        </>
                      )}
                      {!croppedThumbnail && (
                        <div className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-y-4 md:tw-gap-y-6 tw-absolute tw-left-1/2 tw-top-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2 tw-z-50'>
                          <Upload width={40} height={40} fill={'#3D4863'} />
                          <span className='tw-text-xs xs:tw-text-base sm:tw-text-xs xl:tw-text-base tw-text-center tw-text-[#3D4863]  md:tw-whitespace-nowrap'>
                            Upload a poster for your event (3 : 4)
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='tw-flex sm:tw-hidden tw-relative tw-flex-row tw-self-end tw-gap-4'>
                    <Button
                      type='button'
                      color='primary'
                      outline
                      disabled={loading}
                      onClick={() => navigate('/event')}
                    >
                      Cancel
                    </Button>

                    {/* For now there is */}
                    <Button type='submit' color='primary' disabled={loading}>
                      Create
                    </Button>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateEvent;
