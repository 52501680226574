import classnames from 'classnames';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import EventCoreService from 'services/event-core.service';
import EventRoleService from 'services/event-role.service';
import { EventRole, Event, EventVerificationForAdmins } from 'types';

import ApproveModal from '../modal/ApproveModal';
import RoleModal from '../modal/RoleModal';

import RequestReviewVerificationModal from './RequestReviewVerificationModal';
import VerificationRequestInfo from './VerificationRequestInfo';
import VerificationRequestParticipants from './VerificationRequestParticipants';
import VerificationRequestRoles from './VerificationRequestRoles';

enum Tab {
  BASIC_INFORMATION = 'BASIC_INFORMATION',
  ATTENDEES = 'ATTENDEES',
  ROLES = 'ROLES',
}

const VerificationRequestDetail = () => {
  useTitle('Event detail', {
    restoreOnUnmount: true,
  });
  const { id: verificationId } = useParams();
  const [event, setEvent] = useState<Event>();
  const [activeTab, setActiveTab] = useState<Tab>(Tab.BASIC_INFORMATION);
  const [loading, setLoading] = useState(false);
  const [openRequestState, setOpenRequestState] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openRole, setOpenRole] = useState<EventRole | null>(null);
  const [roles, setRoles] = useState<Array<EventRole>>([]);
  const [filteredRoles, setFilteredRoles] = useState<Array<EventRole>>([]);
  const [eventVerification, setEventVerification] = useState<EventVerificationForAdmins>();

  const searchRoles = () => {
    const input = document.getElementById('searchbarRole') as HTMLInputElement;
    const filteredList = roles.filter((role: EventRole) => {
      return role.title.toLowerCase().includes(input.value.toLowerCase());
    });
    setFilteredRoles(filteredList);
  };

  const getEventVerification = useCallback(async () => {
    try {
      if (!verificationId) return;
      setLoading(true);
      const { data } = await EventCoreService.getEventVerificationById(verificationId);
      const { payload } = data;
      setEventVerification(payload);
      setEvent(payload.event);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [verificationId]);

  const getEventRoles = useCallback(async () => {
    try {
      if (!verificationId) return;
      setLoading(true);
      const { data } = await EventRoleService.getRolesOfEventVerification(verificationId);
      const { payload } = data;
      setRoles(payload);
      setFilteredRoles(payload);
      setLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [verificationId]);

  useEffect(() => {
    getEventRoles();
  }, [getEventRoles]);

  useEffect(() => {
    getEventVerification();
  }, [getEventVerification]);

  const submitReview = async (mess: string, isVerified: boolean) => {
    try {
      setOpenRequestState(false);
      setLoading(true);
      if (!verificationId) return;
      await EventCoreService.submitReview(verificationId, isVerified, mess);
      await getEventVerification();
      toast.success('Submit review successfully');
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async () => {
    try {
      setOpenApproveModal(false);
      setLoading(true);
      if (!verificationId) return;
      await EventCoreService.submitReview(verificationId, true, 'Approved!');
      await getEventVerification();
      toast.success('Event is approved successfully');
    } catch (error) {
      handleAxiosError(error, (message) => {
        toast.error(message);
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading || !event || !eventVerification)
    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumb title='Event' breadcrumbItem='Event detail' />
          </Container>
          <Col>
            <Card className='tw-relative tw-p-4 xl:tw-p-[1.5rem] tw-flex tw-flex-col tw-items-center lg:tw-flex-row lg:tw-justify-between'>
              <Loading width={400} height={400} />
            </Card>
          </Col>
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <ApproveModal
        show={openApproveModal}
        onCloseClick={() => setOpenApproveModal(false)}
        onApproveClick={() => {
          handleApprove(/* mess: string */);
        }}
      />
      <RequestReviewVerificationModal
        show={openRequestState}
        onCloseClick={() => setOpenRequestState(false)}
        onSendClick={submitReview}
      />

      <RoleModal role={openRole} onCloseClick={() => setOpenRole(null)} />

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Event' breadcrumbItem='Basic' />
        </Container>
        <Col>
          <Nav tabs role='tablist' className='nav-tabs-custom'>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === Tab.BASIC_INFORMATION,
                })}
                onClick={() => {
                  setActiveTab(Tab.BASIC_INFORMATION);
                }}
              >
                Basic information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === Tab.ATTENDEES,
                })}
                onClick={() => {
                  setActiveTab(Tab.ATTENDEES);
                }}
              >
                Attendees
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === Tab.ROLES,
                })}
                onClick={() => {
                  setActiveTab(Tab.ROLES);
                }}
              >
                Roles
              </NavLink>
            </NavItem>
          </Nav>
          <Card>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={Tab.BASIC_INFORMATION} id={Tab.BASIC_INFORMATION}>
                <Suspense
                  fallback={
                    <div>
                      <Loading width={320} height={320} />
                    </div>
                  }
                >
                  <VerificationRequestInfo
                    eventVerification={eventVerification}
                    openApproveModal={() => setOpenApproveModal(true)}
                    setOpenRequestState={setOpenRequestState}
                  />
                </Suspense>
              </TabPane>
              <TabPane tabId={Tab.ATTENDEES} id={Tab.ATTENDEES}>
                <Suspense
                  fallback={
                    <div>
                      <Loading width={320} height={320} />
                    </div>
                  }
                >
                  <VerificationRequestParticipants
                    verificationId={verificationId}
                    roles={filteredRoles}
                  />
                </Suspense>
              </TabPane>
              <TabPane tabId={Tab.ROLES} id={Tab.ROLES}>
                <Suspense
                  fallback={
                    <div>
                      <Loading width={320} height={320} />
                    </div>
                  }
                >
                  <VerificationRequestRoles
                    setOpenRole={setOpenRole}
                    roles={filteredRoles}
                    searchRoles={searchRoles}
                  />
                </Suspense>
              </TabPane>
            </TabContent>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default VerificationRequestDetail;
