import { useFormik } from 'formik';
import { Button, Modal, ModalBody } from 'reactstrap';
import * as Yup from 'yup';

import MarkdownEditor from 'Components/Common/MarkDownEditor';

type RequestReviewVerificationModalProps = {
  show: boolean;
  onSendClick: (comment: string, isVerified: boolean) => Promise<void>;
  onCloseClick: () => void;
};

const VerificationRequestModalWithMessage = ({
  show,
  onCloseClick,
  onSendClick,
}: RequestReviewVerificationModalProps) => {
  const validation = useFormik({
    initialValues: {
      message: '',
    },
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object({
      message: Yup.string().required('Please Enter Message Review'),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      onSendClick(values.message, false)
        .then(() => {
          resetForm();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setSubmitting(false);
          onCloseClick();
        });
    },
  });

  return (
    <Modal
      isOpen={show}
      toggle={() => {}}
      backdrop='static'
      keyboard={false}
      centered
      size='lg'
      className='tw-rounded-[0.5rem]'
    >
      <ModalBody>
        <button
          type='button'
          onClick={onCloseClick}
          className='btn-close position-absolute end-0 top-0 m-3'
          aria-label='Close'
        ></button>

        <form
          onSubmit={validation.handleSubmit}
          className='tw-flex tw-flex-col tw-gap-y-5 tw-items-start'
        >
          <span className='tw-font-bold tw-text-[#3D4863] tw-text-2xl'>
            Submit Verification Request
          </span>

          <p>Once you submit your verification, this event's organizers will see it</p>

          <MarkdownEditor
            value={validation.values.message}
            onChange={(newValue) => void validation.setFieldValue('message', newValue)}
            placeholder='Enter request description...'
          />
          <div className='tw-flex tw-flex-row tw-w-full tw-justify-end tw-items-center tw-gap-x-4'>
            <Button type='button' color='primary' outline onClick={onCloseClick}>
              Cancel
            </Button>
            <Button
              type='submit'
              color='primary'
              className='tw-px-6 tw-py-2 tw-rounded-md tw-font-medium'
            >
              Confirm and Send
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default VerificationRequestModalWithMessage;
