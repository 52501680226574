import { useFormik } from 'formik';
import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

type VerificationRequestModalProps = {
  show: boolean;
  onSendClick: (comment: string) => Promise<void>;
  onCloseClick: () => void;
};

const VerificationRequestModal = ({
  show,
  onCloseClick,
  onSendClick,
}: VerificationRequestModalProps) => {
  const validation = useFormik({
    initialValues: {
      message: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      onSendClick(values.message);
    },
  });

  return (
    <Modal
      isOpen={show}
      toggle={() => {}}
      backdrop='static'
      keyboard={false}
      centered
      size='lg'
      className='tw-rounded-[0.5rem]'
    >
      <ModalBody>
        <button
          type='button'
          onClick={onCloseClick}
          className='btn-close position-absolute end-0 top-0 m-3'
          aria-label='Close'
        ></button>

        <form
          onSubmit={validation.handleSubmit}
          className='tw-flex tw-flex-col tw-gap-y-5 tw-items-start'
        >
          <span className='tw-font-bold tw-text-[#3D4863] tw-text-2xl'>
            Submit Verification Request
          </span>

          <p>
            To have your event officially activated, submit your verification request so that OISP
            specialists can verify it.
            <br></br>
            <br></br>
            Proceed to do this?
          </p>

          <div className='tw-flex tw-flex-row tw-w-full tw-justify-end tw-items-center tw-gap-x-4'>
            <Button type='button' color='primary' outline onClick={onCloseClick}>
              Cancel
            </Button>
            <Button
              type='submit'
              color='primary'
              className='tw-px-6 tw-py-2 tw-rounded-md tw-font-medium'
            >
              Confirm and Send
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default VerificationRequestModal;
