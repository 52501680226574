import { useNavigate } from 'react-router-dom';

import { EventStatus, EventVerificationForAdmins } from 'types';

import defaultEventThumbnail from '../../assets/images/eventDefaultThumnail.png';

type VerificationRequestCardProps = {
  eventVerification: EventVerificationForAdmins;
  isShowVerify: boolean;
};

const VerificationRequestCard = ({
  eventVerification,
  isShowVerify,
}: VerificationRequestCardProps) => {
  const navigate = useNavigate();

  if (!eventVerification) return null;
  const truncateCondition = () => {
    if (eventVerification.event.description.length > 60) {
      const firstLine = eventVerification.event.description.slice(0, 60);
      return firstLine + '...';
    }
    return eventVerification.event.description;
  };
  const text = truncateCondition();
  return (
    <div className='tw-relative tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-[17.5rem] lg:tw-w-[20rem] 2xl:tw-w-[25rem] tw-h-[9rem] tw-overflow-hidden tw-shadow-xl tw-p-2 tw-bg-white tw-rounded-lg tw-border-solid tw-border-[1px] tw-border-[#3D4863]'>
      <img
        alt={`thumbnail of ${eventVerification.event.title}`}
        src={eventVerification.event.poster?.original || defaultEventThumbnail}
        className='tw-w-[6rem] tw-h-[8rem] tw-object-cover tw-rounded-lg'
      />
      <div className='tw-relative tw-flex tw-flex-col tw-w-[10rem] lg:tw-w-[12.5rem] 2xl:tw-w-[17rem] tw-h-[8rem] tw-text-[12px] tw-gap-2'>
        <div className='tw-font-bold tw-truncate tw-w-full'>{eventVerification.event.title}</div>
        <div className='tw-h-[2rem] tw-w-full'>{text}</div>
        <div className=' tw-w-full'>by {eventVerification.requestor?.name}</div>
        <div className='tw-self-end tw-font-bold tw-cursor-pointer tw-mt-3'>
          {isShowVerify === false && eventVerification.event.status === EventStatus.PRE_EVENT && (
            <button
              onClick={() => navigate('/event/verification-requests/' + eventVerification._id)}
              className='tw-bg-primary tw-rounded-[6px] tw-h-[26px] tw-w-[50px] tw-flex tw-items-center tw-justify-center'
            >
              <div className='tw-text-white tw-text-[12px] tw-text-center '>Verify</div>
            </button>
          )}
          {(isShowVerify === true || eventVerification.event.status !== EventStatus.PRE_EVENT) && (
            <button
              className='tw-flex tw-flex-col tw-ml-4'
              onClick={() => navigate('/event/verification-requests/' + eventVerification._id)}
            >
              <div className='tw-text-primary tw-text-[10px] tw-text-center '>View detail</div>
              <div className='tw-w-full tw-h-[1px] tw-bg-primary'></div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerificationRequestCard;
