import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Col, Container } from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import { defaultEvent1, manager } from 'data/defaultEvent';
import dateFormatter from 'helpers/dateFormatter';
import useTitle from 'hooks/useTitle';
import { Event } from 'types';

import defaultEventThumbnail from '../../../assets/images/eventDefaultThumnail.png';
import fakeQr from '../../../assets/images/fakeQR.png';
import RegisterModal from '../modal/RegisterModal';

import CheckInQrModal from './CheckInQrModal';
// import { useParams } from 'react-router-dom';

type InfoRowProps = {
  name: string;
  content: string;
};

const InfoRow = ({ name, content }: InfoRowProps) => {
  return (
    <div className='tw-relative tw-flex tw-flex-row'>
      <Col xs={5} sm={3} lg={3} className='fw-semibold !tw-px-0 tw-self-start'>
        {name}:
      </Col>
      <Col>
        <p className='text-muted tw-mb-1'>{content}</p>
      </Col>
    </div>
  );
};

const EventDetailUser = () => {
  useTitle('Event detail', {
    restoreOnUnmount: true,
  });

  // const { id } = useParams();
  const [event, setEvent] = useState<Event>(defaultEvent1);
  const [openCheckInQrModal, setOpenCheckInQrModal] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // const timeToCoord = (width: number, time: number) => {
  //   const duration = event.endAt - event.startAt;

  //   const coord = ((time - event.startAt) / duration) * width;

  //   return coord;
  // };

  const fetchEvent = useCallback(() => {
    try {
      setLoading(true);
      setEvent(defaultEvent1);
    } catch (error: unknown) {
      // handleAxiosError(error, (message) => toast.error(message));
      toast.error('Somethings went wrong');
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent]);

  if (loading)
    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumb title='Event' breadcrumbItem='Event detail' />
          </Container>
          <Col>
            <Card className='tw-relative tw-p-4 xl:tw-p-[1.5rem] tw-flex tw-flex-col tw-items-center lg:tw-flex-row lg:tw-justify-between'>
              loading
            </Card>
          </Col>
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <CheckInQrModal
        event={event.title}
        qrUrl={fakeQr}
        participantName='John Doe'
        show={openCheckInQrModal}
        onCloseClick={() => setOpenCheckInQrModal(false)}
      />

      <RegisterModal
        show={openRegisterModal}
        onCloseClick={() => setOpenRegisterModal(false)}
        onRegisterClick={() => {
          console.log('register');
        }}
      />

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Event' breadcrumbItem='Event Detail' />
        </Container>
        <Col>
          <Card className='tw-relative tw-p-4 xl:tw-p-[1.5rem] tw-flex tw-flex-col tw-items-center lg:!tw-flex-row lg:tw-justify-between'>
            <img
              alt={`thumbnail of ${event.title}`}
              src={event.poster?.preview || defaultEventThumbnail}
              className='tw-w-[22.5rem] tw-h-[30rem] lg:tw-w-[15rem] lg:tw-h-[20rem] xl:tw-w-[22.5rem] xl:tw-h-[30rem] tw-relative tw-object-cover'
            />
            <div className='tw-flex tw-flex-col tw-items-center tw-relative tw-rounded-lg tw-px-4 xl:tw-pl-10 xl:tw-pr-0 tw-mt-10 lg:tw-mt-0 tw-gap-4'>
              <div className='tw-relative tw-font-bold tw-text-[1.5rem] tw-truncate'>
                {event.title}
              </div>
              <div className='tw-relative tw-max-h-[7.5rem] tw-overflow-scroll no-scrollbar tw-mt-[0.5rem] tw-text-center'>
                {event.description}
              </div>
              <div className='tw-relative tw-flex tw-flex-col tw-items-center tw-w-[20rem] md:tw-w-[40rem] lg:tw-w-[20rem] 2xl:tw-w-[40rem] tw-border-solid tw-border-[1px] tw-border-[#3D4863] tw-p-2 tw-rounded-lg'>
                <div className='tw-font-bold'>Timeline</div>
                {/* <div className='tw-w-full tw-flex tw-flex-row tw-items-center tw-h-[2rem] tw-justify-between tw-mt-2'>
                  <div className='tw-font-bold tw-w-[2.5rem]'>Start</div>
                  <div className='tw-flex md:tw-hidden lg:tw-flex 2xl:tw-hidden tw-h-[1rem] tw-relative tw-items-center tw-justify-center  tw-bg-primary tw-outline tw-outline-1 tw-outline-[#3D4863] tw-outline-offset-1 tw-w-[12.5rem]'>
                    {event.attendancePeriods.map((period) => {
                      return (
                        <div
                          key={period._id}
                          style={{
                            left: timeToCoord(200, period.startAt),
                            width:
                              timeToCoord(200, period.endAt) - timeToCoord(200, period.startAt),
                          }}
                          className={`${
                            period.title === 'Check-in' ? 'tw-bg-[#0F9D58]' : 'tw-bg-[#4295f4]'
                          } tw-absolute tw-h-[16px] `}
                        />
                      );
                    })}
                  </div>
                  <div className='tw-hidden md:tw-flex lg:tw-hidden 2xl:tw-flex tw-h-[1rem] tw-relative tw-items-center tw-justify-center  tw-bg-primary tw-outline tw-outline-1 tw-outline-[#3D4863] tw-outline-offset-1 tw-w-[32.5rem]'>
                    {event.attendancePeriods.map((period) => {
                      return (
                        <div
                          key={period._id}
                          style={{
                            left: timeToCoord(520, period.startAt),
                            width:
                              timeToCoord(520, period.endAt) - timeToCoord(520, period.startAt),
                          }}
                          className={`${
                            period.title === 'Check-in' ? 'tw-bg-[#0F9D58]' : 'tw-bg-[#4295f4]'
                          } tw-absolute tw-h-[16px] `}
                        />
                      );
                    })}
                  </div>
                  <div className='tw-font-bold tw-w-[2.5rem] tw-text-end'>End</div>
                </div>
                <div className='tw-hidden md:tw-flex lg:tw-hidden 2xl:tw-flex tw-flex-row tw-items-center tw-h-[2rem] tw-w-[32.5rem] tw-relative tw-text-[16px]'>
                  {event.attendancePeriods.map((period) => {
                    return (
                      <>
                        <div
                          style={{ left: timeToCoord(520, period.startAt) }}
                          className='tw-absolute tw-h-[2rem] tw-flex tw-justify-center tw-items-center tw-translate-x-[-50%]'
                        >{`${new Date(period.startAt).getHours()}:${new Date(
                          period.startAt
                        ).getMinutes()}`}</div>
                        <div
                          style={{
                            left:
                              (timeToCoord(520, period.startAt) + timeToCoord(520, period.endAt)) /
                              2,
                          }}
                          className='tw-absolute tw-h-[2rem] tw-flex tw-justify-center tw-items-center tw-translate-x-[-50%]'
                        >
                          {period.title}
                        </div>
                        <div
                          style={{ left: timeToCoord(520, period.endAt) }}
                          className='tw-absolute tw-h-[2rem] tw-flex tw-justify-center tw-items-center tw-translate-x-[-50%]'
                        >{`${new Date(period.endAt).getHours()}:${new Date(
                          period.endAt
                        ).getMinutes()}`}</div>
                      </>
                    );
                  })}
                </div>
                <div className='tw-flex md:tw-hidden lg:tw-flex 2xl:tw-hidden tw-flex-row tw-items-center tw-h-[2rem] tw-w-[12.5rem] tw-relative tw-text-[10px]'>
                  {event.attendancePeriods.map((period) => {
                    return (
                      <>
                        <div
                          style={{ left: timeToCoord(200, period.startAt) }}
                          className='tw-absolute tw-h-[2rem] tw-flex tw-justify-center tw-items-center tw-translate-x-[-50%]'
                        >{`${new Date(period.startAt).getHours()}:${new Date(
                          period.startAt
                        ).getMinutes()}`}</div>
                        <div
                          style={{
                            left:
                              (timeToCoord(200, period.startAt) + timeToCoord(200, period.endAt)) /
                              2,
                          }}
                          className='tw-absolute tw-h-[2rem] tw-flex tw-justify-center tw-items-center tw-translate-x-[-50%]'
                        >
                          {period.title}
                        </div>
                        <div
                          style={{ left: timeToCoord(200, period.endAt) }}
                          className='tw-absolute tw-h-[2rem] tw-flex tw-justify-center tw-items-center tw-translate-x-[-50%]'
                        >{`${new Date(period.endAt).getHours()}:${new Date(
                          period.endAt
                        ).getMinutes()}`}</div>
                      </>
                    );
                  })}
                </div> */}
              </div>
              <div className='tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-items-center tw-gap-10 lg:tw-gap-0 lg:tw-justify-between'>
                <div className='tw-flex tw-flex-col tw-w-full lg:tw-w-[25rem] tw-px-10 lg:tw-p-0'>
                  <InfoRow name='Role' content={manager.title} />
                  <InfoRow name='Date' content={dateFormatter(event.startAt)} />
                  <InfoRow
                    name='Time'
                    content={`${new Date(event.startAt).getHours()}:${new Date(
                      event.startAt
                    ).getMinutes()} - ${new Date(event.endAt).getHours()}:${new Date(
                      event.endAt
                    ).getMinutes()}`}
                  />
                  <InfoRow name='Location' content={event.location} />
                </div>
                <img
                  alt='Check-in QR Code'
                  src={fakeQr}
                  onClick={() => setOpenCheckInQrModal(true)}
                  className='tw-relative tw-w-[15rem] lg:tw-w-[10rem] tw-aspect-square tw-border-8 tw-border-[#3D4863] tw-border-solid tw-object-cover tw-rounded-lg tw-p-2'
                />
              </div>
              {/* This button will be hidden if the event is registered and will be handled later when API is ready*/}
              <Button
                type='button'
                color='subsecondary'
                disabled={false}
                onClick={() => setOpenRegisterModal(true)}
              >
                Register
              </Button>
            </div>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default EventDetailUser;
