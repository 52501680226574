import { useState, useEffect } from 'react';
import { Input, Modal, ModalBody } from 'reactstrap';

type FilterModalParticipantListProps = {
  show: boolean;
  onCloseClick: () => void;
  onApplyFilters: (filters: {
    order: 'asc' | 'desc';
    attendanceStatus: boolean | undefined;
    participantStatus: 'official' | 'un-official';
  }) => void;
  currentOrder?: 'asc' | 'desc';
  currentAttendanceStatus?: boolean | undefined;
  currentParticipantStatus?: 'official' | 'un-official';
  fields: ('order' | 'attendanceStatus' | 'participantStatus')[];
};

const FilterModalParticipantList = ({
  show,
  onCloseClick,
  onApplyFilters,
  currentOrder = 'desc',
  currentAttendanceStatus = undefined,
  currentParticipantStatus = 'official',
  fields,
}: FilterModalParticipantListProps) => {
  const [tempOrder, setTempOrder] = useState<'asc' | 'desc'>(currentOrder);
  const [tempAttendanceStatus, setTempAttendanceStatus] = useState<boolean | undefined>(
    currentAttendanceStatus
  );
  const [tempParticipantStatus, setTempParticipantStatus] = useState<'official' | 'un-official'>(
    currentParticipantStatus
  );

  useEffect(() => {
    if (show) {
      setTempOrder(currentOrder);
      setTempAttendanceStatus(currentAttendanceStatus);
      setTempParticipantStatus(currentParticipantStatus);
    }
  }, [show, currentOrder, currentAttendanceStatus, currentParticipantStatus]);

  const handleApplyFilter = () => {
    onApplyFilters({
      order: tempOrder,
      attendanceStatus: tempAttendanceStatus,
      participantStatus: tempParticipantStatus,
    });
  };

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      size='sm'
      contentClassName='!tw-border-none'
    >
      <ModalBody className='tw-h-full'>
        <div className='tw-flex tw-flex-col tw-gap-y-3 tw-w-[80%] md:tw-w-full tw-mx-auto'>
          {fields.includes('order') && (
            <>
              <div className='tw-flex tw-flex-col tw-w-full tw-gap-y-1 tw-items-start'>
                <h3 className='tw-font-semibold tw-text-[16px] tw-h-[20px] tw-text-primary tw-mb-0'>
                  Order
                </h3>
                <Input
                  id='orderSelect'
                  className='form-control tw-w-full'
                  name='orderSelect'
                  defaultValue={currentOrder}
                  value={tempOrder}
                  onChange={(e) => {
                    const order = e.target.value;
                    setTempOrder(order === 'asc' ? 'asc' : 'desc');
                  }}
                  type='select'
                  placeholder='Order'
                >
                  <option key='asc' value='asc'>
                    Ascending
                  </option>
                  <option key='desc' value='desc'>
                    Descending
                  </option>
                </Input>
              </div>
              <div className='tw-bg-[#CFD4D9] tw-w-full tw-h-[1px]' />
            </>
          )}

          {fields.includes('attendanceStatus') && (
            <>
              <div className='tw-flex tw-flex-col tw-w-full tw-gap-y-1 tw-items-start'>
                <h3 className='tw-font-semibold tw-text-[16px] tw-text-primary tw-mb-0'>
                  Attendance Status
                </h3>
                <Input
                  id='statusSelect'
                  className='form-control tw-w-full'
                  name='statusSelect'
                  value={
                    tempAttendanceStatus === undefined
                      ? 'undefined'
                      : tempAttendanceStatus
                      ? 'true'
                      : 'false'
                  }
                  onChange={(e) => {
                    const selectedStatus = e.target.value;
                    if (selectedStatus === 'undefined') {
                      setTempAttendanceStatus(undefined);
                    } else {
                      setTempAttendanceStatus(selectedStatus === 'true');
                    }
                  }}
                  type='select'
                  placeholder='Attendance Status'
                >
                  <option key='undefined' value='undefined'>
                    All
                  </option>
                  <option key='true' value='true'>
                    Attended
                  </option>
                  <option key='false' value='false'>
                    Absent
                  </option>
                </Input>
              </div>
              <div className='tw-bg-[#CFD4D9] tw-w-full tw-h-[1px]' />
            </>
          )}

          {fields.includes('participantStatus') && (
            <>
              <div className='tw-flex tw-flex-col tw-w-full tw-gap-y-1 tw-items-start'>
                <h3 className='tw-font-semibold tw-text-[16px] tw-text-primary tw-mb-0'>
                  Participant Status
                </h3>
                <Input
                  id='participantStatusSelect'
                  className='form-control tw-w-full'
                  name='participantStatusSelect'
                  value={tempParticipantStatus}
                  onChange={(e) => {
                    const selectedStatus = e.target.value;
                    if (selectedStatus === 'official') {
                      setTempParticipantStatus('official');
                    } else {
                      setTempParticipantStatus('un-official');
                    }
                  }}
                  type='select'
                  placeholder='Pariticipant Status'
                >
                  <option key='official' value='official'>
                    Official
                  </option>
                  <option key='un-official' value='un-official'>
                    Unofficial
                  </option>
                </Input>
              </div>
              <div className='tw-bg-[#CFD4D9] tw-w-full tw-h-[1px]' />
            </>
          )}

          <div className='tw-mx-auto tw-w-full tw-flex tw-flex-row tw-gap-x-[16px] tw-justify-between'>
            <button
              className='tw-bg-[rgb(11,40,120,0.4)] tw-w-[140px] tw-h-[40px] tw-rounded-lg tw-items-center tw-justify-center'
              onClick={onCloseClick}
            >
              <p className='tw-text-center tw-font-semibold tw-text-white tw-text-[12px] 2xs:tw-text-[16px] tw-mb-0'>
                Cancel
              </p>
            </button>
            <button
              className='tw-bg-primary tw-w-[140px] tw-h-[40px] tw-rounded-lg tw-items-center tw-justify-center'
              onClick={handleApplyFilter}
            >
              <p className='tw-text-white tw-font-semibold tw-text-center tw-text-[12px] 2xs:tw-text-[16px] tw-mb-0'>
                Apply Filters
              </p>
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FilterModalParticipantList;
