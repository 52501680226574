import MetisMenu from 'metismenujs';
import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar, { SimpleBarCore } from 'simplebar-react';

import withRouter, { RouterProps } from 'Components/Common/withRouter';
import useAppSelector from 'hooks/useAppSelector';
import { RootState } from 'slices';
import { AllPermissions, Permission } from 'types';

type SidebarContentProps = RouterProps;

type TempSimpleBarCore = {
  recalculate: () => void;
  getScrollElement: () => HTMLElement | null;
};
const SidebarContent = ({ router }: SidebarContentProps) => {
  const ref = useRef<SimpleBarCore | null>(null);

  const { user } = useAppSelector((state: RootState) => state.Login);

  const permissionSet = useMemo(() => {
    if (user?.isManager)
      return new Set(
        Object.keys(Permission).map((key) => Permission[key as keyof typeof Permission])
      );
    return new Set(user?.permissions);
  }, [user]);

  const activateParentDropdown = useCallback((item: HTMLElement) => {
    item.classList.add('active');
    const parent: Element | null = item.parentElement;
    const parent2El: Element | undefined = parent?.children[1];

    if (parent2El && parent2El?.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.children[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.children[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items: HTMLElement[]) => {
    for (let i = 0; i < items.length; ++i) {
      const item: Element = items[i];
      const parent: Element | null = items[i].parentElement;

      if (item && item.classList.contains('active')) {
        item.classList.remove('active');
      }
      if (parent) {
        const parent2El: Element | null =
          parent.children && parent.children.length && parent.children[1]
            ? parent.children[1]
            : null;
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.remove('mm-show');
        }

        parent.classList.remove('mm-active');
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove('mm-show');

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('mm-active'); // li
            parent3.children[0].classList.remove('mm-active');

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove('mm-show'); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('mm-show'); // li
                parent5.children[0].classList.remove('mm-active'); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName: string | undefined = router?.location.pathname;
    let matchingMenuItem: HTMLAnchorElement | null = null;
    const ul: HTMLElement | null = document.getElementById('side-menu');
    const itemsCollection = ul?.getElementsByTagName('a');
    const items: HTMLAnchorElement[] | undefined = Array.from(itemsCollection || []);
    if (!items) {
      console.error('cannot call activeMenu()');
      return;
    }
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [router?.location.pathname, activateParentDropdown]);

  useEffect(() => {
    (ref?.current as TempSimpleBarCore).recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu('#side-menu');
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: HTMLElement) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight && ref?.current) {
        const scrollHTMLElement: HTMLElement | null = (
          ref.current as TempSimpleBarCore
        ).getScrollElement();
        if (scrollHTMLElement) {
          scrollHTMLElement.scrollTop = currentPosition - 300;
        }
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className='h-100' ref={ref}>
        <div id='sidebar-menu'>
          <ul className='metismenu list-unstyled' id='side-menu'>
            <li>
              <Link to='/dashboard'>
                <i className='bx bx-home-circle'></i>
                <span>Home</span>
              </Link>
            </li>
            {(permissionSet.size > 1 ||
              (permissionSet.size === 1 && permissionSet.has(Permission.STUDENT))) && (
              <li className='menu-title'>Admin</li>
            )}
            {permissionSet.has(Permission.READ_STUDENT_INFO) && (
              <li>
                <Link to='/#' className='has-arrow'>
                  <i className='bx bxs-user-detail'></i>
                  <span>Student</span>
                </Link>
                <ul className='sub-menu'>
                  <li>
                    <Link to='/student-info'>Information</Link>
                  </li>
                  <li>
                    <Link to='/student-info/history'>History</Link>
                  </li>
                </ul>
              </li>
            )}
            {user?.isManager && (
              <li>
                <Link to='/storage'>
                  <i className='bx bx-file'></i>
                  <span>Storage</span>
                </Link>
              </li>
            )}
            {user?.isManager && (
              <li>
                <Link to='/access-levels'>
                  <i className='bx bx-task'></i>
                  <span>Access Level</span>
                </Link>
              </li>
            )}
            {permissionSet.has(Permission.READ_CERTIFICATE_BATCH) && (
              <li>
                <Link to='/#' className='has-arrow'>
                  <i className='bx bx-detail'></i>
                  <span>Certificate</span>
                </Link>
                <ul className='sub-menu'>
                  <li>
                    <Link to='/certificate'>Certificate List</Link>
                  </li>
                  {/* Hide temporarily. Please do not remove this  */}

                  {/* {permissionSet.has(Permission.READ_CERTIFICATE_TEMPLATE) && (
                    <li>
                      <Link to='/certificate/template'>Template List</Link>
                    </li>
                  )} */}
                </ul>
              </li>
            )}
            {AllPermissions.EVENT_SYSTEM.some((item) => permissionSet.has(item.permission)) && (
              <li>
                <Link to='/#' className='has-arrow'>
                  <i className='bx bxs-flag'></i>
                  <span>Event</span>
                </Link>
                <ul className='sub-menu'>
                  {AllPermissions.EVENT_SYSTEM.map(
                    (item) =>
                      permissionSet.has(item.permission) && (
                        <li key={item.permission}>
                          <Link to={item.linkTo}>{item.title}</Link>
                        </li>
                      )
                  )}
                </ul>
              </li>
            )}
            {/* Hide temporarily. Please do not remove this  */}

            {/* {permissionSet.has(Permission.READ_SUPPORT_TICKET) && (
              <li>
                <Link to='/contact-system'>
                  <i className='bx bxs-contact'></i>
                  <span>Contact System</span>
                </Link>
              </li>
            )} */}

            {/* Hide temporarily. Please do not remove this  */}

            {/* {permissionSet.has(Permission.READ_UNIT) && (
              <li>
                <Link to='/unit-management'>
                  <i className='bx bxs-group'></i>
                  <span>Unit management </span>
                </Link>
              </li>
            )} */}
            <li className='menu-title'>User</li>
            <li>
              <Link to='/user/certificate'>
                <i className='bx bx-chalkboard'></i>
                <span>My Certificates</span>
              </Link>
            </li>
            <li>
              <Link to='/event'>
                <i className='bx bxs-flag'></i>
                <span>My Events</span>
              </Link>
            </li>

            {/* Hide temporarily. Please do not remove this  */}

            {/* <li>
              <Link to='/user/event'>
                <i className='bx bx-flag'></i>
                <span>Event forum</span>
              </Link>
            </li> */}
            <li>
              <Link to='/profile'>
                <i className='bx bx-user-circle'></i>
                <span>Account</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};
export default withRouter(SidebarContent);
