import Lottie from 'react-lottie';
import Markdown from 'react-markdown';
import { Modal, ModalBody } from 'reactstrap';

import useAppSelector from 'hooks/useAppSelector';
import { EventVerificationForAdmins, EventVerificationForOrganizers } from 'types';

import groupDiscussion from '../../../assets/animations/group-discussion.json';
import adminIcon from '../../../assets/svg/admin.svg';

type ShowMessagesVerificationModalProps = {
  show: boolean;
  onCloseClick: () => void;
  eventVerification: EventVerificationForOrganizers | EventVerificationForAdmins | undefined;
};

const ShowMessagesVerificationModal = ({
  show,
  onCloseClick,
  eventVerification,
}: ShowMessagesVerificationModalProps) => {
  const { user } = useAppSelector((state) => state.Login);
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: groupDiscussion,
    rendererSettings: {
      preserveAspectRatio: 'none',
    },
  };

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered
      size='lg'
      className='tw-rounded-[0.5rem] tw-flex tw-items-center tw-justify-center'
    >
      <button
        type='button'
        onClick={onCloseClick}
        className='btn-close position-absolute end-0 top-0 m-3 tw-z-10'
      ></button>
      <ModalBody className='tw-w-items-center tw-justify-centertw-w-full tw-h-full tw-max-h-[660px] tw-overflow-x-auto no-scrollbar'>
        {eventVerification !== undefined && eventVerification?.reviews.length > 0 ? (
          <div className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4'>
            {eventVerification?.reviews?.map((review) => {
              const reviewDate = new Date(
                review.createdAt.toString().length === 10
                  ? review.createdAt * 1000
                  : review.createdAt
              );
              return (
                <>
                  <div className='tw-flex tw-flex-col tw-justify-start tw-w-[90%] tw-gap-y-2 tw-p-2'>
                    <div className='tw-flex tw-flex-row tw-gap-2 tw-items-center tw-justify-start'>
                      <img
                        className='tw-w-[40px] tw-h-[40px] tw-rounded-full tw-mb-0'
                        src={review.user?.picture || adminIcon}
                        alt={`'User's profile`}
                        onError={(e) => {
                          e.currentTarget.src = adminIcon;
                        }}
                      />
                      <div className='tw-flex tw-flex-row tw-items-start xs:tw-items-center tw-justify-center tw-gap-x-2 tw-mb-0'>
                        <h2 className='tw-text-primary tw-font-bold tw-text-[16px] xs:tw-text-[20px] tw-mb-0'>
                          {review.user?.name}
                          {review.user?._id === eventVerification.requestor?._id &&
                            review.user?._id !== user?._id && (
                              <span className=' tw-font-semibold tw-text-[14px] tw-mb-0 tw-ml-1'>
                                (Creator)
                              </span>
                            )}
                          {review.user?._id === user?._id && (
                            <span className=' tw-font-semibold tw-text-[14px] tw-mb-0 tw-ml-1'>
                              (You)
                            </span>
                          )}
                          <span className=' tw-text-[14px] tw-font-normal tw-mb-0 tw-ml-2'>
                            {reviewDate.toLocaleTimeString('en-US', {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                            })}{' '}
                            {reviewDate.toLocaleDateString('en-GB')}
                          </span>
                        </h2>
                      </div>
                    </div>
                    <div
                      key={review._id}
                      className='tw-border-2 tw-rounded-lg tw-border-solid tw-border-primary tw-p-1 '
                    >
                      <Markdown className='tw-p-2 tw-mb-0 tw-bg-gray-50 tw-whitespace-normal tw-break-words tw-mt-0'>
                        {review.comment}
                      </Markdown>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          <div className=' tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-[400px] tw-gap-y-8'>
            <div className='tw-w-[240px] tw-h-[240px]'>
              <Lottie
                options={defaultOptions1}
                height='100%'
                width='100%'
                style={{
                  pointerEvents: 'none',
                }}
              />
            </div>
            <h2 className='tw-font-bold tw-text-center tw-text-[20px] md:tw-text-[26px] tw-text-primary'>
              There are currently no reviews!
            </h2>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ShowMessagesVerificationModal;
