import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { toast } from 'react-toastify';
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import Loading from 'Components/Common/LoadingIndicator';
import { handleAxiosError } from 'helpers/handleError';
import { secondsToTimeDate } from 'helpers/timeConverter';
import { toStartCase } from 'helpers/translate';
import EventPeriodService from 'services/event-period.service';
import { EventPeriod, EventVerificationForAdmins } from 'types';

import defaultEventThumbnail from '../../../assets/images/eventDefaultThumnail.png';
import ShowMessagesVerificationModal from '../modal/ShowMessagesVerificationModal';

type VerificationRequestInfoProps = {
  eventVerification: EventVerificationForAdmins;
  openApproveModal: () => void;
  setOpenRequestState: (open: boolean) => void;
};

const VerificationRequestInfo = ({
  eventVerification,
  openApproveModal,
  setOpenRequestState,
}: VerificationRequestInfoProps) => {
  const startTime = useMemo(
    () => secondsToTimeDate(eventVerification.event.startAt),
    [eventVerification.event]
  );
  const endTime = useMemo(
    () => secondsToTimeDate(eventVerification.event.endAt),
    [eventVerification.event]
  );
  const [loading, setLoading] = useState(false);
  const [eventTimeLine, setEventTimeLine] = useState<EventPeriod[]>([]);
  const [showMessagesVerificationModal, setShowMessagesVerificationModal] =
    useState<boolean>(false);

  const getEventTimeline = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await EventPeriodService.getEventTimeline(eventVerification.event._id);
      const { payload } = data;
      setEventTimeLine(payload);
      setLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [eventVerification.event._id]);

  useEffect(() => {
    getEventTimeline();
  }, [getEventTimeline]);

  if (!eventVerification) return null;
  if (loading)
    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumb title='Application' breadcrumbItem='Event detail' />
          </Container>
          <Col>
            <Card className='tw-relative tw-p-4 xl:tw-p-[1.5rem] tw-flex tw-flex-col tw-items-center lg:tw-flex-row lg:tw-justify-between'>
              <Loading width={400} height={400} />
            </Card>
          </Col>
        </div>
      </React.Fragment>
    );
  return (
    <>
      <ShowMessagesVerificationModal
        show={showMessagesVerificationModal}
        onCloseClick={() => setShowMessagesVerificationModal(false)}
        eventVerification={eventVerification}
      />
      <Form
        autoComplete='off'
        className='tw-p-5 tw-p-t-[1.5rem] tw-h-full xl:tw-p-6 xl:tw-p-t-[1.75rem] tw-rounded-b-[0.25rem] tw-shadow-[0_8px_16px_0_rgba(61,72,99,0.25)] tw-flex !tw-flex-col tw-items-center sm:tw-items-start sm:!tw-flex-row tw-gap-6 xl:tw-justify-between'
      >
        {/* Basic info section */}
        <div className='tw-flex tw-flex-col tw-gap-y-6 tw-w-full sm:tw-w-fit'>
          <div className='tw-flex tw-flex-row tw-items-center md:tw-h-fit tw-justify-between '>
            <span className='tw-font-bold tw-text-[#3D4863] tw-text-lg xl:tw-text-xl'>Poster</span>
          </div>
          <div className='tw-self-center tw-flex tw-flex-col tw-w-[15rem] xs:tw-w-[22.5rem] sm:tw-w-[15rem] lg:tw-w-[18rem] 2xl:tw-w-[22.5rem] tw-group tw-aspect-[3/4] tw-items-center tw-justify-center tw-rounded-xl tw-bg-[#A3ACC2] tw-cursor-pointer tw-relative'>
            <>
              <img
                src={eventVerification.event.poster?.preview || defaultEventThumbnail}
                className='tw-w-full tw-h-full tw-object-fill'
                alt='event thumbnail'
              />
            </>
          </div>
        </div>
        <div className='tw-order-first sm:tw-order-last tw-w-full xl:tw-min-h-[33.25rem] !tw-flex !tw-flex-col xl:!tw-justify-between  '>
          <div className='!tw-flex !tw-flex-col tw-h-full tw-mb-11'>
            <span className='tw-text-[#3D4863] tw-h-[2.5rem] tw-flex tw-items-center tw-font-bold xl:tw-text-xl tw-mb-2 xl:tw-mb-4 tw-w-full tw-whitespace-nowrap'>
              Event Detail
            </span>
            <FormGroup className='tw-mb-2' row>
              <Label
                htmlFor='title'
                className='col-form-label tw-w-full xl:!tw-w-[7.5rem] tw-font-bold'
              >
                Title:
              </Label>
              <Col className='tw-w-full'>
                <Input
                  id='title'
                  className='form-control'
                  name='title'
                  type='text'
                  readOnly={true}
                  value={eventVerification.event.title}
                />
              </Col>
            </FormGroup>
            <FormGroup className='tw-mb-2' row>
              <Label
                htmlFor='description'
                className='col-form-label tw-w-full xl:!tw-w-[7.5rem] tw-font-bold'
              >
                Description:
              </Label>
              <Col className='tw-w-full'>
                <Input
                  tag='textarea'
                  id='description'
                  className='form-control'
                  name='description'
                  type='text'
                  rows={4}
                  readOnly={true}
                  value={eventVerification.event.description}
                />
              </Col>
            </FormGroup>
            <FormGroup className='tw-mb-2' row>
              <Label
                htmlFor='location'
                className='col-form-label tw-w-full xl:!tw-w-[7.5rem] tw-font-bold'
              >
                Location:
              </Label>
              <Col className='tw-w-full'>
                <Input
                  id='location'
                  className='form-control'
                  name='location'
                  type='text'
                  value={eventVerification.event.location}
                  readOnly={true}
                />
              </Col>
            </FormGroup>
            <FormGroup className='tw-mb-2 tw-items-center' row>
              <Label
                htmlFor='location'
                className='col-form-label tw-w-full xl:!tw-w-[7.5rem] tw-font-bold'
              >
                Status:
              </Label>
              <Col className='tw-w-full'>
                <Badge
                  color='primary'
                  className='tw-w-fit tw-h-fit'
                  style={{
                    borderRadius: '0.5rem',
                    paddingTop: '0.25rem',
                    paddingBottom: '0.25rem',
                    paddingLeft: '0.5rem',
                    paddingRight: '0.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  }}
                >
                  {toStartCase(eventVerification.event.status)}
                </Badge>
              </Col>
            </FormGroup>
            <FormGroup className='tw-mb-2' row>
              <Label
                htmlFor='time'
                className='col-form-label tw-w-full xl:!tw-w-[7.5rem] tw-font-bold'
              >
                Time:
              </Label>
              <Col className='tw-w-full tw-flex tw-flex-col xl:tw-flex-row tw-gap-2 md:tw-gap-4'>
                <Label htmlFor='startAt' className='col-form-label tw-w-fit'>
                  From:
                </Label>
                <div className='tw-flex tw-flex-col'>
                  <Flatpickr
                    name='startAt'
                    className='form-control'
                    value={eventVerification.event.startAt}
                    options={{
                      mode: 'single',
                      altFormat: 'd/m/Y H:i',
                      dateFormat: 'd/m/Y H:i',
                      enableTime: true,
                    }}
                    disabled={true}
                    style={{
                      backgroundColor: '#ffffff',
                    }}
                  />
                </div>
                <Label htmlFor='endAt' className='col-form-label tw-w-fit'>
                  to:
                </Label>
                <div className='tw-flex tw-flex-col'>
                  <Flatpickr
                    name='endAt'
                    className='form-control'
                    value={eventVerification.event.endAt}
                    options={{
                      mode: 'single',
                      altFormat: 'd/m/Y H:i',
                      dateFormat: 'd/m/Y H:i',
                      enableTime: true,
                    }}
                    disabled={true}
                    style={{
                      backgroundColor: '#ffffff',
                    }}
                  />
                </div>
              </Col>
            </FormGroup>
            <FormGroup className='tw-mb-2 tw-flex tw-justify-start tw-items-start ' row>
              <Label
                htmlFor='timeline'
                className='col-form-label tw-w-full xl:!tw-w-[7.5rem] tw-font-bold'
              >
                Timeline:
              </Label>
              <Col className='tw-w-full tw-flex tw-flex-col tw-items-start tw-mt-2'>
                {eventTimeLine.map((period) => {
                  return (
                    <div key={period._id} className='tw-flex tw-flex-row tw-space-x-2'>
                      <span className='tw-font-semibold'>{period.title},</span>
                      <span>{startTime}</span>
                      <span>-</span>
                      <span>{endTime}</span>
                    </div>
                  );
                })}
              </Col>
            </FormGroup>
            <FormGroup className='tw-mb-2' row>
              <Label
                htmlFor='verification'
                className='col-form-label tw-w-full xl:!tw-w-[7.5rem] tw-font-bold'
              >
                Requests:
              </Label>
              <Col className='tw-w-full tw-flex tw-align-middle'>
                <Button
                  type='button'
                  outline={true}
                  color='primary'
                  onClick={() => {
                    setShowMessagesVerificationModal(true);
                  }}
                  className='tw-relative tw-flex tw-items-center tw-px-4 tw-py-2 tw-w-[80px] tw-h-[50px] tw-text-base'
                >
                  <span className='tw-font-semibold tw-text-[16px] tw-mb-0'>View</span>
                  <div className='tw-w-[24px] tw-h-[24px] tw-text-white tw-rounded-full tw-bg-[#6D7EAE] tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-right-0 tw-transform tw-translate-x-1/2 -tw-translate-y-1/2'>
                    {eventVerification?.reviews.length}
                  </div>
                </Button>
              </Col>
            </FormGroup>
          </div>
        </div>

        {!eventVerification.isVerified && (
          <div className='tw-hidden sm:tw-flex tw-absolute tw-self-end tw-flex-row tw-right-0 tw-mr-5 tw-gap-4'>
            <button
              type='button'
              className='tw-border tw-border-[#EA4335] tw-bg-white tw-text-[#EA4335] tw-font-semibold tw-px-4 tw-py-2 tw-rounded tw-transition-all tw-duration-200 hover:tw-bg-[#EA4335] hover:tw-text-white'
              onClick={() => setOpenRequestState(true)}
            >
              Request modification
            </button>
            <button
              type='button'
              className='tw-border tw-border-primary tw-bg-primary tw-text-white tw-font-semibold tw-px-4 tw-py-2 tw-rounded '
              onClick={openApproveModal}
            >
              Verify
            </button>
          </div>
        )}
        {/* thumbnail section */}

        {!eventVerification.isVerified && (
          <div className='tw-flex sm:tw-hidden tw-relative tw-flex-row tw-self-end tw-gap-4'>
            <button
              type='button'
              className='tw-border tw-border-[#EA4335] tw-bg-white tw-text-[#EA4335] tw-font-semibold tw-px-4 tw-py-2 tw-rounded tw-transition-all tw-duration-200 hover:tw-bg-[#EA4335] hover:tw-text-white'
              onClick={() => setOpenRequestState(true)}
            >
              Request modification
            </button>
            <button
              type='button'
              className='tw-border tw-border-primary tw-bg-primary tw-text-white tw-font-semibold tw-px-4 tw-py-2 tw-rounded '
              onClick={openApproveModal}
            >
              Verify
            </button>
          </div>
        )}
      </Form>
    </>
  );
};

export default VerificationRequestInfo;
