import React, { useEffect, useRef, useCallback } from 'react';
import { BarcodeScanner, DetectedBarcode, ScanOptions } from 'react-barcode-scanner';
import 'react-barcode-scanner/polyfill';
import { toast } from 'react-toastify';
import { Button, Input, Modal, ModalBody, TabContent, TabPane } from 'reactstrap';
import { useDebounceCallback } from 'usehooks-ts';

import { EventPeriod } from 'types';

type ScanBarCodeModalProps = {
  eventPeriods: EventPeriod[];
  show: boolean;
  onCloseClick: () => void;
  scanningPhase: string;
  setScanningPhase: (newData: 'Scanning' | 'Processing' | 'Done') => void;
  getParticipantByStudentId: (newdata: string) => Promise<void>;
  setScannedPeriodId: (newdata: string) => void;
  isScanning: boolean;
  setIsScanning: (newdata: boolean) => void;
};
const scanOptions: ScanOptions = {
  delay: 1000,
  formats: ['code_128', 'qr_code'],
};
const ScanBarCodeModal = ({
  eventPeriods,
  show,
  onCloseClick,
  scanningPhase,
  setScanningPhase,
  getParticipantByStudentId,
  setScannedPeriodId,
  isScanning,
  setIsScanning,
}: ScanBarCodeModalProps) => {
  const setScanningPhaseRef = useRef(setScanningPhase);

  useEffect(() => {
    setScanningPhaseRef.current = setScanningPhase;
  }, [setScanningPhase]);

  const debouncedGetParticipant = useCallback(
    async (studentId: string) => {
      try {
        await getParticipantByStudentId(studentId);
        setScanningPhaseRef.current('Done');
      } catch (err) {
        console.error('Error fetching participant:', err);
        toast.error('Failed to fetch participant.');
        setScanningPhaseRef.current('Scanning');
      } finally {
        setIsScanning(false);
      }
    },
    [setIsScanning, getParticipantByStudentId]
  );

  const debounced = useDebounceCallback(debouncedGetParticipant, 500);

  return (
    <Modal
      isOpen={show}
      toggle={!isScanning ? onCloseClick : undefined}
      centered={true}
      size='sm'
      backdrop={isScanning ? 'static' : true}
      keyboard={!isScanning}
    >
      <div className='modal-content '>
        <ModalBody className='tw-p-2 tw-flex tw-flex-col tw-items-center tw-gap-4'>
          <TabContent>
            <TabPane>
              <div className='tw-flex tw-flex-col tw-items-center tw-gap-4'>
                <div className='tw-font-bold tw-text-[1.5rem]'>Attendance registration</div>
                <Input
                  id='type'
                  name='type'
                  type='select'
                  className='tw-w-[8rem] tw-h-[2.5rem] tw-flex tw-items-center tw-relative'
                  placeholder='Enter period type...'
                  onChange={(e) => {
                    const value = e.target.value;
                    setScannedPeriodId(value);
                  }}
                >
                  {eventPeriods.map((period) => (
                    <option key={period._id} value={period._id}>
                      {period.title}
                    </option>
                  ))}
                </Input>
                <div className='tw-relative tw-w-[15rem] tw-flex tw-flex-col'>
                  <BarcodeScanner
                    options={scanOptions}
                    onCapture={(barcodes: DetectedBarcode[]) => {
                      if (isScanning || !barcodes.length) return;

                      if (barcodes && barcodes.length > 0) {
                        setIsScanning(true);
                        setScanningPhase('Processing');
                        const studentId = barcodes[0].rawValue;
                        debounced(studentId);
                      }
                    }}
                    className='tw-relative tw-w-[15rem] tw-aspect-square tw-border-8 tw-border-[#3D4863] tw-border-solid tw-rounded-lg'
                  />
                </div>
                <div
                  className={`${
                    scanningPhase === 'Done'
                      ? 'tw-text-[#0F9D58]'
                      : scanningPhase === 'Done'
                      ? 'tw-text-[#F1B44C]'
                      : ''
                  } tw-font-bold`}
                >
                  {scanningPhase}
                </div>
              </div>
            </TabPane>
          </TabContent>
          <Button type='button' color='primary' onClick={onCloseClick}>
            Close
          </Button>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ScanBarCodeModal;
