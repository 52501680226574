import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Input, Modal, ModalBody } from 'reactstrap';

import { toStartCase } from 'helpers/translate';
import UserService from 'services/user.service';
import { EventStatus } from 'types';

type FilterModalProps = {
  show: boolean;
  onCloseClick: () => void;
  onApplyFilters: (filters: {
    order: 'asc' | 'desc';
    status?: EventStatus;
    creator?: string;
  }) => void;
  currentOrder: 'asc' | 'desc';
  currentStatus: EventStatus | undefined;
  currentCreator: string | undefined;
};

const FilterModal = ({
  show,
  onCloseClick,
  onApplyFilters,
  currentOrder,
  currentStatus,
  currentCreator,
}: FilterModalProps) => {
  const [filterModalOrder, setFilterModalOrder] = useState<'asc' | 'desc'>(currentOrder);
  const [filterModalStatus, setFilterModalStatus] = useState<EventStatus | undefined>(
    currentStatus
  );
  const [filterModalCreatedBy, setFilterModalCreatedBy] = useState<string | undefined>(
    currentCreator
  );

  useEffect(() => {
    if (show) {
      setFilterModalOrder(currentOrder);
      setFilterModalStatus(currentStatus);
      setFilterModalCreatedBy(currentCreator);
    }
  }, [show, currentOrder, currentStatus, currentCreator]);

  const handleCreator = useCallback(async () => {
    try {
      const { data } = await UserService.getMyProfile();
      const { payload } = data;
      setFilterModalCreatedBy(payload._id);
    } catch (error: unknown) {
      toast.error('Failed to fetch user profile.');
    }
  }, []);
  const handleApply = () => {
    onApplyFilters({
      order: filterModalOrder,
      status: filterModalStatus,
      creator: filterModalCreatedBy,
    });
  };

  const handleCancel = () => {
    onCloseClick();
  };

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      size='sm'
      contentClassName='!tw-border-none'
    >
      <ModalBody className='tw-h-full'>
        <div className='tw-flex tw-flex-col tw-gap-y-3 tw-w-[80%] md:tw-w-full tw-mx-auto'>
          <div className='tw-flex tw-flex-col tw-w-full tw-gap-y-1 tw-items-start'>
            <h3 className='tw-font-semibold tw-text-[16px] tw-h-[20px] tw-text-primary tw-mb-0'>
              Order
            </h3>
            <Input
              id='orderSelect'
              className='form-control tw-w-full'
              name='orderSelect'
              defaultValue={currentOrder}
              value={filterModalOrder}
              onChange={(e) => {
                const order = e.target.value;
                setFilterModalOrder(order === 'asc' ? 'asc' : 'desc');
              }}
              type='select'
              placeholder='Order'
            >
              <option key='asc' value='asc'>
                Ascending
              </option>
              <option key='desc' value='desc'>
                Descending
              </option>
            </Input>
          </div>

          <div className='tw-bg-[#CFD4D9] tw-w-full tw-h-[1px]' />

          <div className='tw-flex tw-flex-col tw-w-full tw-gap-y-1 tw-items-start'>
            <h3 className='tw-font-semibold tw-text-[16px] tw-text-primary tw-mb-0'>Status</h3>
            <Input
              id='statusSelect'
              className='form-control tw-w-full'
              name='statusSelect'
              defaultValue={currentStatus}
              value={filterModalStatus ?? 'all'}
              onChange={(e) => {
                const status = e.target.value;
                if (status === 'all') {
                  setFilterModalStatus(undefined);
                } else {
                  setFilterModalStatus(status as EventStatus);
                }
              }}
              type='select'
              placeholder='Status'
            >
              <option value={'all'}>All phases</option>
              {Object.values(EventStatus)
                .filter((status) => status !== EventStatus.DELETED)
                .map((value) => (
                  <option key={value} value={value}>
                    {toStartCase(value)}
                  </option>
                ))}
            </Input>
          </div>
          <div className='tw-bg-[#CFD4D9] tw-w-full tw-h-[1px]' />
          <div className='tw-flex tw-flex-row tw-w-full tw-gap-y-1 tw-items-start'>
            <span className='tw-mr-3 tw-font-semibold tw-text-[16px] tw-text-primary tw-mb-0'>
              Create By Me
            </span>

            <Input
              type='checkbox'
              id='creatorCheckbox'
              name='creatorCheckbox'
              checked={!!filterModalCreatedBy}
              className='tw-scale-125'
              onChange={(e) => {
                if (e.target.checked) {
                  handleCreator();
                } else {
                  setFilterModalCreatedBy(undefined);
                }
              }}
            />
          </div>
          <div className='tw-bg-[#CFD4D9] tw-w-full tw-h-[1px]' />
          <div className='tw-mx-auto tw-w-full tw-flex tw-flex-row tw-gap-x-[16px] tw-justify-between'>
            <button
              className='tw-bg-[rgb(11,40,120,0.4)] tw-w-[140px] tw-h-[40px] tw-rounded-lg tw-items-center tw-justify-center'
              onClick={handleCancel}
            >
              <p className='tw-text-center tw-font-semibold tw-text-white tw-text-[12px] 2xs:tw-text-[16px] tw-mb-0'>
                Cancel
              </p>
            </button>
            <button
              className='tw-bg-primary tw-w-[140px] tw-h-[40px] tw-rounded-lg tw-items-center tw-justify-center'
              onClick={handleApply}
            >
              <p className='tw-text-white tw-font-semibold tw-text-center tw-text-[12px] 2xs:tw-text-[16px] tw-mb-0'>
                Apply Filters
              </p>
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FilterModal;
